<template>
	<div class="Apply">
		<div v-if="Type==1">
			<div class="Title">
				<h2>ISCS2.0 云端账户申请</h2>
			</div>
			<div class="information">
				如果您需要ISCS2.0账户，请填写下面的表格，我们将尽快为您开通。
			</div>
			<div class="Form">
				<el-form ref="RuleForm" :rules="Rules" :model="Form" label-position="top" label-width="100px">
					<el-form-item label="公司名称" prop="companyname">
						<el-input maxlength="32" suffix-icon="el-icon-school" show-word-limit v-model="Form.companyname" placeholder="请输入公司名称"></el-input>
					</el-form-item>
					<el-form-item label="联络人名称" prop="name">
						<el-input maxlength="32" suffix-icon="el-icon-s-custom" show-word-limit v-model="Form.name" placeholder="请输入联络人名称"></el-input>
					</el-form-item>
					<el-form-item label="联络人电话" prop="telephone">
						<el-input maxlength="11" suffix-icon="el-icon-phone-outline" show-word-limit v-model="Form.telephone" placeholder="请输入联络人电话"></el-input>
					</el-form-item>
					<div class="Button">
						<el-button type="primary" icon="el-icon-position" round @click="submit">提交申请</el-button>
					</div>
				</el-form>
			</div>
		</div>
		<div v-else-if="Type==2">
			<div class="Status-icon flex-center">
				<i class="el-icon-success Status-success"></i>
			</div>
			<div class="Status-Title">
				提交成功
			</div>
			<div class="Status-Describe">
				提交申请成功，正在审核中
			</div>
			<div class="Status-button">
				<el-button type="primary" size="medium" @click="Type=1">返回</el-button>
			</div>
		</div>
		<div v-else-if="Type==3">
			<div class="Status-icon flex-center">
				<i class="el-icon-s-promotion Status-primary"></i>
			</div>
			<div class="Status-Title">
				审核通过
			</div>
			<div class="Status-Describe">
				您的ISCS2.0云端账户申请已通过
			</div>
			<div class="Status-button">
				<el-button type="primary" size="medium" @click="login">去登录</el-button>
			</div>
		</div>
		<div v-else-if="Type==4">
			<div class="Status-icon flex-center">
				<i class="el-icon-error Status-error"></i>
			</div>
			<div class="Status-Title">
				审核通过
			</div>
			<div class="Status-Describe">
				您的ISCS2.0云端账户申请已通过
			</div>
			<div class="Status-button">
				<el-button type="primary" size="medium" @click="Type=1">重新申请</el-button>
				<el-button type="primary" size="medium" @click="Type=1">返回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Apply',
		data (){
			return {
				//页面类型
				Type:1,
				//表单参数
				Form:{
					Type:0,//0-普通申请,1-重新申请
					companyname: "",//公司名称
					name: "",//联络人名称
					telephone: "",//联络人电话
				},
				//表单校验
				Rules:{
					companyname:[{required:true,message:'请输入公司名称',trigger: 'blur'}],
					name:[{required:true,message:'请输入联络人名称',trigger: 'blur'}],
					telephone:[{required:true,message:'请输入联络人电话',trigger: 'blur'}]
				},
		    }
	    },
		methods: {
			//提交申请
			submit(){
				this.$refs.RuleForm.validate(async valid => {
					if (!valid) return;
					this.DialogButtonLoad = true;//显示按钮加载动画
					var {data: res} = await this.$http.post('addBsApply', this.Form);
					if (this.ReturnFiltration(res.code)) {
						var Status = res.result.Status;
						if(Status=="1")
						{
							//申请成功/审核中
							this.Type = 2;
							this.Form = {Type:0,companyname: "",name: "",telephone: ""};
						}
						else if(Status=="2")
						{
							//审核通过
							this.Type = 3;
							this.Form = {Type:0,companyname: "",name: "",telephone: ""};
						}
						else if(Status=="3")
						{
							//审核未通过
							this.Type = 4;
							this.Form.Type =1;
						}
					}
					this.DialogButtonLoad = false;//隐藏按钮加载动画
				})
			},
			//跳转至登录界面
			login(){
				window.location.href=this.$LoginUrl;
			},
			// 返回过滤
			ReturnFiltration(code) {
				if (code == "100001") {
					//成功
					return true;
				} else if (code == "100004") {
					//格式不正确
					this.Message('请求参数不正确', "error");
					return false;
				} else if (code == "100005") {
					//操作失败
					this.Message('操作失败', "error");
					return false;
				}
			},
			//提醒消息
			Message(message, type) {
				this.$message({showClose: true,message: message,type: type,offset: "20"});
			}
		}
	}
</script>

<style scoped>
	.Apply{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		background-color: #3B5D78;
	}
	
	.Apply>div{
		width: 500px;
		margin: 0px auto;
		padding: 30px;
		border-radius: 5px;
		background-color: #FFFFFF;
	}
	
	/* 界面1 */
	.Title{
		color: #409EFF;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 40px;
	}
	
	.information{
		text-align: center;
		color: #909399;
	}
	
	.Form{
		margin-top: 40px;
	}
	
	.Button{
		margin-top: 80px;
		width: 100%;
	}
	
	.Button>button{
		width: 100%;
	}
	
	/* 界面2 */
	.Status-icon{
		width: 100%;
		margin: 20px 0px;
	}
	
	.Status-icon>i{
		font-size: 100px;
	}
	
	.Status-primary{
		color: #409EFF;
	}
	
	.Status-success{
		color: #67C23A;
	}
	
	.Status-error{
		color: #F56C6C;
	}
	
	.Status-Title{
		width: 100%;
		text-align: center;
		font-size: 15px;
		margin: 15px 0px;
		color: #606266;
	}
	
	.Status-Describe{
		width: 100%;
		text-align: center;
		font-size: 15px;
		color: #606266;
	}
	.Status-button{
		text-align: center;
		margin-top: 30px;
	}
</style>

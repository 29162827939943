<template>
	<div class="Home">
		<div class="Top">
			<div class="Top-Index1">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">激活公司数量</div>
							<div class="Top-Left-Value">{{companyCount}}</div>
							<div class="Top-Left-describe">激活软件公司数量</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index1 flex-center">
								<i class="el-icon-box"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Top-Index2">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">最新用户记录数</div>
							<div class="Top-Left-Value">{{employeesCount}}</div>
							<div class="Top-Left-describe">最新上传人员总数</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index2 flex-center">
								<i class="el-icon-user"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Top-Index3">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">最新设备记录数</div>
							<div class="Top-Left-Value">{{controllerCount}}</div>
							<div class="Top-Left-describe">最新上传设备总数</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index3 flex-center">
								<i class="el-icon-mobile-phone"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Top-Index4">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">今日公司更新数</div>
							<div class="Top-Left-Value">{{updateCompanyCount}}</div>
							<div class="Top-Left-describe">今日更新记录数</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index4 flex-center">
								<i class="el-icon-refresh"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="Statistics">
			<div class="Statistics-Left">
				<div class="Statistics-Title">
					<div>ISCS安装激活</div>
					<div class="Card-hint">
						<div>
							<i class="el-icon-remove" style="color: #23CCB3;"></i>&nbsp;近一个月激活数
						</div>
					</div>
				</div>
				<div id="homeCardRecord" class="homeCardRecord"></div>
			</div>
			<div class="Statistics-Right">
				<div id="homecontrollerType" class="homecontrollerType"></div>
			</div>
		</div>
		<div class="Bottom">
			<div class="Timeline flex-center">
				<div class="Timeline-content">
					<div v-for="(item,index) in HistoryRecord" :key="item.recordid" class="Timeline-Index">
						<div :class="RecordType(item.recordtype,'COlOR')">
							<i :class="RecordType(item.recordtype,'ICON')"></i>
						</div>
						<div class="Timeline-Index-content">
							<div class="Timeline-Index-Value">
								<div class="Timeline-Index-Title">{{item.title}}</div>
								<div class="Timeline-Value">{{item.description}}</div>
								<div class="Timeline-Index-DateTime">
									<div>时间</div>
									<div class="Timeline-DateTime">{{item.recorddatetime}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Apply">
				<div class="Apply-Title flex-items-center">
					公司数据详情
					<div>{{companyStatistics.length}}</div>
				</div>
				<div class="Apply-Table">
					<el-table :data="companyStatistics" class="Apply-Table-Style">
						<el-table-column prop="companyname" width="350px" align="left" label="公司名称"></el-table-column>
						<el-table-column prop="os" align="left" label="安装系统"></el-table-column>
						<el-table-column prop="employeescount" align="left" label="用户数">
							<template slot-scope="scope">
								{{scope.row.employeescount==undefined?0:scope.row.employeescount}} 人
							</template>
						</el-table-column>
						<el-table-column prop="controllercount" align="left" label="设备数">
							<template slot-scope="scope">
								{{scope.row.controllercount==undefined?0:scope.row.controllercount}} 台
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data (){
			return {
				//本地安装公司数
				companyCount:0,
				//最新用户记录数
				employeesCount:0,
				//最新设备记录数
				controllerCount:0,
				//今日公司更新数
				updateCompanyCount:0,
				//近一个月软件安装激活
				HistoryRecord:[],
				//公司数据统计
				companyStatistics: []
		    }
	    },
		//完成html渲染后执行
		mounted(){
			//查询数量
			this.queryCsBsCount();
			//查询近一个月软件安装激活
			this.queryCsRecentlyActivate();
			//查询系统占比
			this.queryCsOS();
			//查询历史记入
			this.queryCsHistoryRecord();
			//查询数据统计
			this.queryCsCompanyStatistics();
		},
		methods: {
			//查询数量
			async queryCsBsCount(){
				try 
				{
					var {data: res} = await this.$http.post('queryCsBasicsCount');
					if (this.ReturnFiltration(res.code)) {
						this.companyCount=res.result.companyCount;
						this.employeesCount=res.result.employeesCount;
						this.controllerCount=res.result.controllerCount;
						this.updateCompanyCount=res.result.updateCompanyCount;
					}
				} 
				catch (e) 
				{
					this.$notify.error({title: '错误',message:e});
				}
			},
			//查询近一个月软件安装激活
			async queryCsRecentlyActivate(){
				try {
					var {data: res} = await this.$http.post('queryCsRecentlyActivate');
					if (this.ReturnFiltration(res.code)) 
					{
						var Record = res.result.arrList;
						for(var i = 0;i<Record.length;i++){
							var date = new Date(Record[i].adddattime);
							var year = date.getFullYear();
							var month = date.getMonth() + 1;
							var day = date.getDate();
							var formattedDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
							Record[i].adddattime = formattedDate;
						}
						let myCard = this.$echarts.init(document.getElementById('homeCardRecord'),'light');
						var option = {
							color: ['#23CCB3'],
							tooltip: {
							    trigger: 'axis'
							},
							grid: {
								left: '3%',
								top: '5%',
								right: '3%',
								bottom: '10%',
								containLabel: true
							},
							xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data: this.TimeQuantum()
						    },
						    yAxis: {
						        type: 'value',
								splitNumber: 3,
								splitLine: {
								    show: true,
								    lineStyle:{
								        type:'dashed'
								    }
								}
						    },
						    series: [
								{
									name:'安装激活数',
									smooth: true,
									data:this.statistic(Record),
									type: 'line',
									areaStyle: {},
									symbolSize: 10,
									symbol: 'circle',
									lineStyle: {
										color: "#23CCB3",
										width: 3,
									},
									areaStyle:{
										color:"#C4FCEF"
									}
								}
							]
						};
						myCard.setOption(option);
						//设置自动适应页面大小
						window.onresize = function(){
						    myCard.resize();
						}
					}
				} catch (e) {
					this.$notify.error({title: '错误',message:e});
				}
			},
			//时间段
			TimeQuantum(){
				var datesArray = [];
				var currentDate = new Date();
				for (var i = 29; i >= 0; i--) {
					var date = new Date(currentDate);
					date.setDate(date.getDate() - i);
					var year = date.getFullYear();
					var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
					var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
					var formattedDate = year + '-' + month + '-' + day;
					datesArray.push(formattedDate);
				}
				return datesArray;
			},
			//一个月软件安装激活值
			statistic(record){
				var arrList = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
				var currentDate = new Date();
				for (var i = 29; i >= 0; i--) {
					var date = new Date(currentDate);
					date.setDate(date.getDate() - i);
					var year = date.getFullYear();
					var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
					var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
					var dateString = year + '-' + month + '-' + day;
					var count = 0;
					for(var k=0;k<record.length;k++){
						if(dateString == record[k].adddattime){
							count++;
						}
					}
					arrList [i] = count;
				}
				return arrList;
			},
			//查询系统占比
			async queryCsOS(){
				try
				{
					var {data: res} = await this.$http.post('queryCsOS');
					if (this.ReturnFiltration(res.code)) {
						var systemCount = res.result.arrList;
						let myChart = this.$echarts.init(document.getElementById('homecontrollerType'),'light');
						var option = {
							color: ['#409EFF','#23CCB3','#FAAE42','#FF6E40','#D63384','#6610F2'],
							title: {
						        text: '安装OS占比',
								subtext: 'ISCS安装OS系统占比',
						        left: 'center',
								top:'6%',
								textStyle:{
									fontSize: 15,
								}
						    },
						    tooltip: {
						        trigger: 'item'
						    },
						    legend: {
						        top: '85%',
						        left: 'center'
						    },
						    series: [
						        {
						            name: 'OS数量',
						            type: 'pie',
						            radius: ['40%', '60%'],
									avoidLabelOverlap: false,
									itemStyle: {
										borderRadius: 5,
										borderColor: '#fff',
										borderWidth: 2
									},
									label: {
										show: false,
										position: 'center'
									},
									labelLine: {
										show: false
									},
						            data:this.systemType(systemCount)
						        }
						    ]
						};
						myChart.setOption(option);
						//设置自动适应页面大小
						window.onresize = function(){
						    myChart.resize();
						}
					}
				} catch (e) {
					this.$notify.error({title: '错误',message:e});
				}
				
			},
			//系统类型占比
			systemType(controller){
				var arrList = [];
				for(var i=0;i<controller.length;i++)
				{
					var status = false;
					for(var k=0;k<arrList.length;k++){
						if(arrList[k].name == controller[i].os){
							arrList[k].value ++;
							status = true;
						}
					}
					if(!status){
						arrList[arrList.length] = {name:controller[i].os,value:1}
					}
				}
				return arrList;
			},
			//查询历史记入
			async queryCsHistoryRecord(){
				try 
				{
					var {data: res} = await this.$http.post('queryCsHistoryRecord');
					if (this.ReturnFiltration(res.code)) {
						this.HistoryRecord=res.result.HistoryRecord;
					}
				} 
				catch (e) 
				{
					this.$notify.error({title: '错误',message:e});
				}
			},
			//记录类型
			RecordType(recordtype,Type){
				if(recordtype==1) 
				{
					//权限更新
					return Type=="ICON"?"el-icon-refresh":"Timeline-Index-Icon flex-center TimelineType1";
				}
				else if(recordtype==2)
				{
					//软件激活
					return Type=="ICON"?"el-icon-key":"Timeline-Index-Icon flex-center TimelineType2";
				}
				else if(recordtype==3)
				{
					//使用申请
					return Type=="ICON"?"el-icon-s-order":"Timeline-Index-Icon flex-center TimelineType3";
				}
			},
			// 查询公司数据统计
			async queryCsCompanyStatistics() {
				try {
					var {data: res} = await this.$http.post('queryCsCompanyStatistics');
					if (this.ReturnFiltration(res.code)) {
						var companyOs = res.result.companyOs;
						var companyDetails = res.result.companyDetails;
						for(var i=0;i<companyOs.length;i++){
							for(var k=0;k<companyDetails.length;k++){
								if(companyOs[i].companyid == companyDetails[k].companyid){
									companyOs[i].employeescount =  companyDetails[k].employeescount;
									companyOs[i].controllercount =  companyDetails[k].controllercount;
									break;
								}
							}
						}
						this.companyStatistics = companyOs;
					}
				} catch (e) {
					this.$notify.error({title: '错误',message: e});
				}
			},
			//跳转到申请界面
			skip(){
				this.$router.push('/BsApply');//跳转
			},
			// 返回过滤
			ReturnFiltration(code) {
				if (code == "100001") {
					//成功
					return true;
				} else if (code == "100002") {
					//Session过期
					this.Message('身份已过期，请重新登录', "warning");
					return false;
				} else if (code == "100003") {
					//校验失败
					this.Message('校验失败，请重新登录', "error");
					return false;
				} else if (code == "100004") {
					//格式不正确
					this.Message('请求参数不正确', "error");
					return false;
				} else if (code == "100005") {
					//操作失败
					this.Message('操作失败', "error");
					return false;
				}
			},
			//提醒消息
			Message(message, type) {
				this.$message({showClose: true,message: message,type: type,offset: "14"});
			}
	  	}
	}
</script>

<style scoped>
	/* 头部数据 */
	.Top{
		width: 100%;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.Top>div{
		width: 24%;
		height: 100%;
		display: flex;
		border-radius: 12px;
		justify-content: flex-end;
	}
	
	.Top>div>div{
		width: 100%;
		height: 100%;
		border-radius: 10px;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.Top-Content{
		width: 90%;
		height: 90%;
		display: flex;
		justify-content: space-between;
	}
	
	.Top-Index-Left{
		height: 100%;
	}
	
	.Top-Left-Name{
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #636363;
		height: 30%;
	}
	
	.Top-Left-Value{
		display: flex;
		align-items: center;
		font-size: 40px;
		font-weight: 700;
		height: 40%;
	}
	
	.Top-Left-describe{
		display: flex;
		align-items: center;
		font-size: 13px;
		color: #959595;
		height: 30%;
	}
	
	.Top-Index-right{
		height: 100%;
	}
	
	.Top-Index-right>div{
		width: 65px;
		height: 65px;
		border-radius: 50%;
	}
	
	.Top-Index-right>div>i{
		color: #FFFFFF;
		font-size: 30px;
	}
	
	.Top-Index1{
		background-color: #23CCB3;
		color: #23CCB3;
	}
	
	.Top-Index2{
		background-color: #409EFF;
		color: #409EFF;
	}
	
	.Top-Index3{
		background-color: #8354C8;
		color: #8354C8;
	}
	
	.Top-Index4{
		background-color: #E94B73;
		color: #E94B73;
	}
	
	.Statistics{
		margin-top: 20px;
		height: 420px;
		display: flex;
		justify-content: space-between;
	}
	
	.Statistics-Left{
		width: 74.6%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 10px;
	}
	.Statistics-Right{
		width: 23.8%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 10px;
	}
	
	.Statistics-Title{
		height: 14%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding:0px 25px;
		font-size: 15px;
		color:#666666;
		font-weight: 700;
		border-radius: 10px 10px 0px 0px;
	}
	
	.Card-hint{
		display: flex;
		align-items: center;
	}
	
	.Card-hint>div{
		display: flex;
		align-items: center;
		font-size: 14px;
		margin: 0px 10px;
		color: #909399;
		font-weight: 500;
	}
	
	.Card-hint i{
		font-size: 20px;
		margin-top: 1px;
	}
	
	.homeCardRecord{
		width: 100%;
		height: 86%;
	}
	
	.homecontrollerType{
		width: 100%;
		height: 100%;
	}
	
	.Bottom{
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
	
	.Timeline{
		width: 40%;
		height: 1080px;
		border-radius: 10px;
		overflow:hidden;
		background-color: #FFFFFF;
	}
	
	.Timeline-content{
		overflow-y:scroll;
		margin-right: -65px;
		height: 92%;
		width: 97%;
	}
	
	.Timeline-Index{
		display: flex;
		width: 100%;
	}
	
	.Timeline-Index-Icon{
		position: relative;
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	
	.TimelineType1{
		background-color: #23CCB3;
	}
	
	.TimelineType2{
		background-color: #409EFF;
	}
	
	.TimelineType3{
		background-color: #1C84C6;
	}
	
	.Timeline-Index-Icon>i{
		color: #FFFFFF;
		font-size: 25px;
	}
	
	.Timeline-Index-content{
		width: 100%;
		margin-top: 4px;
		margin-left: -28px;
		border-left:6px solid #E7EAEC;
	}
	
	.Timeline-Index-Value{
		float: right;
		width: 85%;
		height: 100%;
		margin-right: 30px;
	}
	
	.Timeline-Index-Value>div{
		margin: 15px 0px;
	}
	
	.Timeline-Index-Title{
		font-size: 20px;
		color: #606266;
	}
	
	.Timeline-Value{
		font-size: 15px;
		color: #606266;
	}
	
	.Timeline-Index-DateTime{
		font-size: 15px;
		padding-bottom: 40px;
		color: #606266;
	}
	
	.Timeline-Index-DateTime>div{
		margin: 5px 0px;
	}
	
	.Timeline-DateTime{
		color: #23B7E5;
		font-size: 14px;
	}
	
	/* 申请列表 */
	.Apply{
		width: 58.7%;
		border-radius: 10px;
		background-color: #FFFFFF;
	}
	
	.Apply-Title{
		height: 65px;
		padding-left: 25px;
		font-size: 15px;
		color: #58666E;
		font-weight: 700;
	}
	
	.Apply-Title>div{
		padding: 1px 12px;
		margin-left: 10px;
		border-radius: 3px;
		font-size: 14px;
		color: #FFFFFF;
		background-color: #409EFF;
	}
	
	.Apply-Table{
		height: 980px;
		margin: 10px auto;
		width: 95%;
		overflow-y: auto;
	}
	
	.Apply-Table-Icon{
		font-size: 17px;
		/* color: #409EFF; */
	}
</style>

<template>
 <div class="Login flex-center">
	 <div class="box-card">
		<div class="logo">
			<el-image class="logo-img" :src="logosrc"></el-image>Cerberus
		</div>
		<div class="Card flex-center">
			<div class="Left flex-center">
				<el-image class="img" :src="src"></el-image>
			</div>
			<div class="Right flex-center">
				<div class="Form">
				   <div class="Title">ISCS2.0 软件授权管理平台</div>
				   <div class="Suggest">建议使用谷歌火狐Edge浏览器打开</div>
				   <div class="Form_Value">
					   <el-form ref="FormRef" :model="Form" :rules="FormRules">
						   <el-form-item prop="UserName">
							   <div class="Input flex-center">
								   <el-input class="ElInput" prefix-icon="el-icon-user" v-model="Form.UserName"  placeholder="请输入用户名称"></el-input>
							   </div>
						   </el-form-item>
						   <el-form-item prop="Password">
							   <div class="Input flex-center">
									<el-input class="ElInput" prefix-icon="el-icon-lock" v-model="Form.Password" placeholder="请输入用户密码" show-password></el-input>
							   </div>
						   </el-form-item>
					   </el-form>
					   <div class="Checked">
						   <el-checkbox v-model="Checked">记住用户名密码</el-checkbox>
					   </div>
					   <el-button type="primary" class="logIn" round :loading="loginStatus" @click="login">
							<span v-if="!loginStatus">登 录</span>
							<span v-else>登 录 中...</span>
					   </el-button>
				   </div>
				</div>
		   </div>
	   </div>
	 </div>
  </div>
</template>

<script>
export default {
	name: 'Login',
	data (){
		return {
			src:require("../assets/images/shield2.png"),
			logosrc:require("../assets/images/logo.png"),
			loginStatus:false,
			Form:{
				UserName:"",
			    Password:"",
		    },
		    Checked:false,
		    //表单验证规则
		    FormRules: {
				UserName: [{
					required: true,
		  		    message: '请输入用户名称',
		  		    trigger: 'blur'
		  	    }], 
		  	    Password: [{
				    required: true,
		  		    message: '请输入用户密码',
		  		    trigger: 'blur'
		  	    }] 
		    }
	    }
    },
	created(){
		//回车事件
		this.submit();
	},
	mounted(){
		//读取保存的cookie
		this.getCookie();
	},
	methods: {
		//回车事件
  		submit(){
  			document.onkeydown = e =>{
  				if (e.key === "Enter") {
  					this.login();
  				}
  			}
  		},
  			
  		// 登录校验
  		login() {
  			this.$refs.FormRef.validate(async valid => {
				if (!valid) return;
  				try 
				{
					this.loginStatus = true;//状态改为登录中
					const {data:res} = await this.$http.post('login',this.Form);
					this.loginStatus = false;
					if(this.ReturnFiltration(res.code))
					{
						if(res.result.loginStaus==1)
						{
							//是否存储账号密码
							if(this.Checked)
							{
								//将账号密码存Cookie
								this.setCookie(this.Form.UserName,this.Form.Password,365)
							}
							else
							{
								//将账号密码存Cookie
								this.setCookie(this.Form.UserName,this.Form.Password,0)
							}
							window.sessionStorage.setItem('UserStatus', "1");//保存至sessionStorage
							this.$router.push('/BsStatistics');//跳转
						}
						else
						{
							this.Message("用户名或密码错误","error");
						}
					}
  				} 
				catch (err) {
					this.loginStatus = false;
					this.Message("登录连接超时","error");
  				}
  			})
  		},
		
		//记住登录的账号密码
		//参数：用户名-密码-到期天数
		setCookie(UserName, Password, num) {
			const time = new Date();
			time.setTime(time.getTime() + 24 * 60 * 60 * 1000 * num);
			//加上window.btoa是为了对用户名和密码进行编码，不让cookie明文展示
			window.document.cookie = `userName=${window.btoa(UserName)}; path=/; expires=${time.toGMTString()}`;
			window.document.cookie = `passWord=${window.btoa(Password)}; path=/; expires=${time.toGMTString()}`
		},
				
		//读取cookie
		getCookie() {
			if (document.cookie.length > 0) {
				const cookieArr1 = document.cookie.split(";");
				for (let i in cookieArr1) {
					const cookieArr2 = cookieArr1[i].split("=");
					//避免输入中含有空格，消除文本框空格
					cookieArr2[0] = cookieArr2[0].replace(/\s+/g, "");
					cookieArr2[1] = cookieArr2[1].replace(/\s+/g, "");
					if (cookieArr2[0] == "userName") {
						//读取cookie然后进行解码
						this.Checked = true;
						this.Form.UserName = window.atob(cookieArr2[1]);
					} else if (cookieArr2[0] == "passWord") {
						this.Form.Password = window.atob(cookieArr2[1]);
					}
				}
			}
		},
		// 返回过滤
		ReturnFiltration(code){
		    if(code=="100001")
			{
				//成功
				return true;
			}
			else if(code=="100002")
			{
				//Session过期
				this.Message('身份已过期，请重新登录',"warning");
				return false;
			}
			else if(code=="100003")
			{
				//校验失败
				this.Message('校验失败，请重新登录',"error");
				return false;
			}
			else if(code=="100004")
			{
				//格式不正确
				this.Message('请求参数不正确',"error");
				return false;
			}
			else if(code=="100005")
			{
				//操作失败
				this.Message('操作失败',"error");
				return false;
			}
		},
		//提醒消息
		Message(message,type){
			this.$message({showClose: true,message: message,type: type,offset:"47"});
		}
  	}
}
</script>

<style scoped>
	/* 背景布局 */
	.Login{
		width: 100%;
		height: 100%;
		background-color: #c7c7c7;
		background-image: url(../assets/images/bg.jpg);
		background-size: 200%;
		animation: gradientBG 100s linear infinite;
	}
	
	/* 渐变动画 */
	@keyframes gradientBG {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
	
	/* 中框 */
	.box-card{
		width: 960px;
		height: 680px;
		border-radius: 10px;
		background-color: #FFFFFF;
		box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
	}
	
	.logo{
		width: 100%;
		height: 15%;
		font-size: 22px;
		color: #2C3E50;
		display: flex;
		align-items: center;
	}
	
	.logo-img{
		margin-left: 30px;
		margin-right: 7px;
		width: 40px;
		height: 40px;
	}
	
	.Card{
		width: 100%;
		height: 70%;
		display: flex;
	}
	
	/* 中框左侧 */
	.Left{
		width: 44%;
		height: 100%;
		border-radius: 6px;
	}
	
	/* 图标 */
	.img{
		width: 316px;
		height: 289px;
	}
	
	/* 中框右侧 */
	.Right{
		width: 44%;
		height: 100%;
		text-align: center;
	}
	
	/* 表单 */
	.Form{
		width: 80%;
	}
	
	/* 标题 */
	.Title{
		margin: 10px 0px;
		font-size: 28px;
	}
	
	/* 建议 */
	.Suggest{
		margin: 5px 0px;
		font-size: 15px;
		color: #7B7B7B;
		margin-bottom: 70px;
	}
	
	/* 表单内容 */
	.Form_Value{
		width: 90%;
		margin: 0px auto;
	}
	
	/* 文本框 */
	.Input{
		padding: 4px 0px;
		background-color: #E6E6E6;
		border-radius: 30px;
	}
	
	/* 文库图标 */
	.Input>i{
		margin: 0px 10px;
		font-size: 30px;
		color: #409EFF;
	}
	
	/* 文本框数属性 */
	.ElInput{
		width: 95%;
		font-size: 15px;
	}
	
	/* 去掉文本框边框 */
	.Input>>>.el-input__inner {
	    border: 0;
		background-color: #E6E6E6;
	}
	
	/* 文本框图标属性 */
	.Input>>>.el-input__icon{
		color: #666666;
		font-size: 18px;
	}
	
	/* 文本框校验提示 */
	.Form_Value>>>.el-form-item__error{
		position:absolute;
		top:100%;
		left:10px;
	}
	
	/* 记住密码 */
	.Checked{
		margin-left: 17px;
		width: 100%;
		text-align: left;
	}
	
	.logIn{
		font-size: 17px;
		margin-top: 40px;
		width: 100%;
		height: 45px;
	}
</style>

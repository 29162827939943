<template>
	<div class="BsApply">
		<div class="top">
			<div class="top-left">
				 <el-radio-group v-model="QueryBsApply.Status" @change="selectBsApply()">
				      <el-radio-button label="1">待审核</el-radio-button>
				      <el-radio-button label="2">已审核</el-radio-button>
				      <el-radio-button label="0">所有申请</el-radio-button>
				    </el-radio-group>
				<div class="ConditionType">
					<el-select v-model="QueryBsApply.ConditionType" placeholder="请选择条件" @change="selectBsApply()">
						<el-option label="公司名称" value="1"></el-option>
						<el-option label="联系人名称" value="2"></el-option>
						<el-option label="联系人电话" value="3"></el-option>
					 </el-select>
				 </div>
				 <div class="Condition">
					<el-input placeholder="请输入内容" prefix-icon="el-icon-search"v-model="QueryBsApply.Condition" @input="selectBsApply()"></el-input>
				 </div>
			</div>
			<div class="top-right">
				<el-button size="small" icon="el-icon-refresh-right" @click="selectBsApply()">刷新</el-button>
			</div>
		</div>
		<div class="centre">
			<div class="Table">
				<el-table :data="tableData" border class="Table-Attribute" highlight-current-row @selection-change="TableCheckbox" height="100%">
					<el-table-column type="selection" align="center"></el-table-column>
					<el-table-column prop="companyname" align="center" label="公司名称"></el-table-column>
					<el-table-column prop="name" align="center"  label="联系人"></el-table-column>
					<el-table-column prop="telephone" align="center" label="手机号"></el-table-column>
					<el-table-column prop="status" align="center" label="状态">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.status==1" type="warning">待审核</el-tag>
							<el-tag v-else-if="scope.row.status==2" type="success">已审核</el-tag>
							<el-tag v-else-if="scope.row.status==3" type="danger">已拒绝</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="applydattime" align="center" label="添加日期"></el-table-column>
					<el-table-column align="center" fixed="right"  label="操作">
						<template slot-scope="scope">
							<div v-if="scope.row.status==1">
								<el-tooltip effect="dark" content="同意" :enterable="false" placement="top">
									<el-button size="mini" type="success" icon="el-icon-check" @click="Yes(scope.row)"></el-button>
								</el-tooltip>
								<el-tooltip effect="dark" content="拒绝" :enterable="false" placement="top">
									<el-button size="mini" type="danger" icon="el-icon-close" @click="No(scope.row)"></el-button>
								</el-tooltip>
							</div>
							<div v-else>
								<el-tag v-if="scope.row.status==2" type="success">已审核</el-tag>
								<el-tag v-else-if="scope.row.status==3" type="danger">已拒绝</el-tag>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="bottom">
			共 {{ApplyCount}} 条 <el-pagination background layout="prev, pager, next" @current-change="CurrentChange" :page-size="20" :total="ApplyCount"></el-pagination>
		</div>
		<el-dialog title="账户配置" :visible.sync="DialogVisible" width="60%">
			<div class="Dialog-Form">
				<div class="Dialog-Form-Left">
					<el-form ref="RuleForm" :rules="Rules" :model="Form" label-width="100px">
						<el-form-item label="公司名称" prop="companyname">
							<el-input maxlength="32" suffix-icon="el-icon-school" show-word-limit v-model="Form.companyname" placeholder="请输入公司名称"></el-input>
						</el-form-item>
						<el-form-item label="联系人" prop="name">
							<el-input maxlength="32" suffix-icon="el-icon-s-custom" show-word-limit v-model="Form.name" placeholder="请输入联系人名称"></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="telephone">
							<el-input maxlength="11" suffix-icon="el-icon-phone-outline" show-word-limit v-model="Form.telephone" placeholder="请输入联系人手机号"></el-input>
						</el-form-item>
						<el-form-item label="登录名" prop="username">
							<el-input maxlength="32" suffix-icon="el-icon-user" show-word-limit v-model="Form.username" placeholder="请输入最高管理员登录名"></el-input>
						</el-form-item>
						<el-form-item label="登录密码" prop="userpassword">
							<el-input maxlength="32" suffix-icon="el-icon-lock" show-password v-model="Form.userpassword" placeholder="请输入最高管理员登录密码"></el-input>
						</el-form-item>
						<el-form-item label="高级配置">
							<el-switch v-model="AdvancedSetup" active-color="#13ce66" inactive-text="关闭" active-text="开启"></el-switch>
						</el-form-item>
						<div v-if="AdvancedSetup">
							<el-form-item label="授权账户数" prop="authorizationaccount">
								<el-input maxlength="3" suffix-icon="el-icon-user-solid" show-word-limit v-model="Form.authorizationaccount" placeholder="请输入授权账户数"></el-input>
							</el-form-item>
							<el-form-item label="二维码密钥" prop="secretkey">
								<el-input maxlength="32" suffix-icon="el-icon-key" show-password show-word-limit v-model="Form.secretkey" placeholder="请输入二维码密钥"></el-input>
							</el-form-item>
							<el-form-item label="物业代码" prop="eid">
								<el-input maxlength="4" suffix-icon="el-icon-office-building" show-word-limit v-model="Form.eid" placeholder="请输入物业代码"></el-input>
							</el-form-item>
							<el-form-item label="读卡扇区" prop="icseradd">
								<el-input maxlength="2" suffix-icon="el-icon-menu" show-word-limit v-model="Form.icseradd" placeholder="请输入读卡扇区号"></el-input>
							</el-form-item>
							<el-form-item label="扇区密钥" prop="icserkey">
								<el-input maxlength="12" suffix-icon="el-icon-key" show-password show-word-limit v-model="Form.icserkey" placeholder="请输入扇区密钥"></el-input>
							</el-form-item>
							<el-form-item v-for="(item,index) in Form.companyauthority" :key="item.authoritykey" :label="item.authoritydescribe">
								<div class="Form-CompanyAuthority">
									<div class="Form-CompanyAuthority-Switch">
										<el-switch v-if="item.authoritykey=='Basic'" v-model="item.authorityvalue" @change="BasicSwitch" active-color="#13ce66" inactive-text="关闭" active-text="开启"></el-switch>
										<el-switch v-if="item.authoritykey!='Basic'" v-model="item.authorityvalue" @change="ModuleSwitch" active-color="#13ce66" inactive-text="关闭" active-text="开启"></el-switch>
									</div>
									<div class="Form-CompanyAuthority-Time">
										<el-tag type="info">有效期 {{TimeTransformDay(item.Time)}}</el-tag>&nbsp;&nbsp;&nbsp;
										<el-date-picker :disabled="!item.authorityvalue" type="datetimerange" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
										 range-separator="至" start-placeholder="开始日期"end-placeholder="结束日期" 
										 v-model="Form.companyauthority[index].Time"></el-date-picker>
									</div>
								</div>
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button :loading="DialogButtonLoad" type="primary" @click="updateApplyStatus()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'BsApply',
		data (){
			//校验手机号
			var checkTelephone = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				}
				
				//校验格式
				var myreg = /^[0-9]*$/;
				if (!myreg.test(value)) {
				    return callback(new Error('手机号格式不正确'));
				}
			};
			//校验邮箱格式
			var checkEmail = async (rule, value, callback) => {
				//校验邮箱格式
				if(value!="")
				{
					var myreg =  /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
					if (!myreg.test(value)) {
						return callback(new Error('邮箱格式不正确'));
					}
				}
			};
			//校验账户数
			var checkauthorizationaccount = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error('授权账户数不能为空'));
				}
				
				//校验格式
				var myreg = /^[0-9]*$/;
				if (!myreg.test(value)) {
				    return callback(new Error('格式不正确'));
				}
			};
			//校验扇区号
			var checkicseradd = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error('扇区号不能为空'));
				}
				
				//校验格式
				var myreg = /^[0-9]*$/;
				if (!myreg.test(value)) {
				    return callback(new Error('格式不正确'));
				}
				//校验格式
				if(value<0 || value>15)
				{
					return callback(new Error('格式不正确'));
				}
			};
			return {
				//列表加载状态
				ApplyLoadStatus: false,
				QueryBsApply: {
					Status:"1",//类型
					Condition: "", //条件值
					ConditionType: '1', //条件类型
					Count: 20, //条数
					Pages: 1, //页数
					SearchDateTime:[] //日期
				},
				//条数
				ApplyCount: 0,
				//申请信息
				tableData: [],
				//同意/拒绝
				ststus:0,
				//申请ID
				recordId:0,
				//勾选的ID
				TableCheckbox:[],
				//同意对话框显示状态
				DialogVisible: false,
				//新增修改按钮加载状态
				DialogButtonLoad:false,
				//高级设置
				AdvancedSetup:false,
				//表单信息
				Form: {
					companyid:0,//公司ID
					companyname: "",//公司名称
					companycode: "",//公司代码
					companylogo: "",//公司Logo
					companynote: "",//公司简介
					authorizationstarttime:"",//授权开始时间
					authorizationendtime:"",//授权结束时间
					name: "",//联络人名称
					telephone: "",//联络人电话
					email: "",//邮箱
					username:"",//登录名
					userpassword:"",//登录密码
					authorizationaccount:"10",//授权账户数
					secretkey:"CBAB2018AP@#",//二维码密钥
					eid:this.EID(),//物业代码
					icseradd:"8",//读卡扇区
					icserkey:"FFFFFFFFFFF0",//扇区密钥
					companyauthority:[] //权限
				},
				//表单校验
				Rules:{
					companyname:[{required:true,message:'请输入公司名称',trigger: 'blur'}],
					companycode:[{required:true,message:'请输入公司代码',trigger: 'blur'}],
					name:[{required:true,message:'请输入联系人名称',trigger: 'blur'}],
					telephone:[{required:true,validator:checkTelephone,trigger: 'blur'}],
					email:[{validator:checkEmail,trigger: 'blur'}],
					username:[{required:true,message:'请输入登录密码',trigger: 'blur'}],
					userpassword:[{required:true,message:'请输入登录密码',trigger: 'blur'}],
					authorizationaccount:[{required:true,validator:checkauthorizationaccount,trigger: 'blur'}],
					secretkey:[{required:true,message:'请输入二维码密钥',trigger: 'blur'}],
					eid:[{required:true,message:'请输入物业代码',trigger: 'blur'}],
					icseradd:[{required:true,validator:checkicseradd,trigger: 'blur'}],
					icserkey:[{required:true,message:'请输入扇区密钥',trigger: 'blur'}]
				},
		    }
	    },
		mounted(){
			this.selectBsApply();
		},
		methods: {
			// 查询申请
			async selectBsApply() {
				try {
					this.ApplyLoadStatus = true; //显示加载动画
					var {data: res} = await this.$http.post('selectBsApply', this.QueryBsApply);
					this.ApplyLoadStatus = false; //关闭加载动画
					if (this.ReturnFiltration(res.code)) {
						this.tableData = res.result.Query;
						this.ApplyCount = res.result.Count; //条数
					}
				} catch (e) {
					this.$notify.error({title: '错误',message: "QueryCs_Company:" + e});
					this.ApplyLoadStatus = false;
				}
			},
			//分页
			CurrentChange(index){
				this.QueryBsApply.Pages = index;
				this.selectBsApply();
			},
			//同意
			Yes(e){
				this.ststus = 1;
				this.recordId = e.recordid;
				this.DialogVisible = true;
				this.Form = {
					'companyid':0,//公司ID
					'companyname': e.companyname,//公司名称
					'companycode':'',//公司代码
					'companylogo': '',//公司Logo
					'companynote': e.companynote,//公司简介
					'authorizationstarttime':'',//授权开始时间
					'authorizationendtime':'',//授权结束时间
					'name': e.name,//联络人名称
					'telephone': e.telephone,//联络人电话
					'email': '',//邮箱
					'username':'',//登录名
					'userpassword':'',//登录密码
					'authorizationaccount':'10',//授权账户数
					'secretkey':'CBAB2018AP@#',//二维码密钥
					'eid':this.EID(),//物业代码
					'icseradd':'8',//读卡扇区
					'icserkey':'FFFFFFFFFFF0',//扇区密钥
					'companyauthority':[
						{'authoritykey':'Basic','authorityvalue':true,'Time':[this.ThisTime(0),this.ThisTime(365)],'authoritydescribe':'基本功能'},
						{'authoritykey':'Visitor','authorityvalue':false,'Time':[this.ThisTime(0),this.ThisTime(365)],'authoritydescribe':'访客模块'},
						{'authoritykey':'Lift','authorityvalue':false,'Time':[this.ThisTime(0),this.ThisTime(365)],'authoritydescribe':'梯控模块'},
					]
				}
			},
			//拒绝
			No(e){
				this.$confirm('确定要拒绝申请吗?', '提示', {type: 'warning'}).then(async () => {
					var {data: res} = await this.$http.post('updateApplyStatus', {Status:2,companyId:[],recordId:e.recordid,Form:{}});
					if (this.ReturnFiltration(res.code)) {
						if(res.result.Status=='1')
						{
							this.Message('已拒绝',"success");
							this.selectBsApply();
						}
						else
						{
							this.Message('操作失败',"error");
						}
					}
				}).catch(() => {
					//取消
				});
			},
			//当前时间
			ThisTime(Day){
				var Time = new Date();
				Time.setDate(Time.getDate()+Day);
				var year = Time.getFullYear();
				var month = Time.getMonth()+1;
				var day = Time.getDate();
				if(month<10) month = "0"+month;
				if(day<10) day = "0"+day;
				var hours = Time.getHours();
				var mins = Time.getMinutes();
				var secs = Time.getSeconds();
				var msecs = Time.getMilliseconds();
				if(hours<10) hours = "0"+hours;
				if(mins<10) mins = "0"+mins;
				if(secs<10) secs = "0"+secs;
				if(msecs<10) secs = "0"+msecs;
				if(Day>0)
				{
					return year+"-"+month+"-"+day+" 23:59:59";
				}
				else
				{
					return year+"-"+month+"-"+day+" 00:00:00";
				}
			},
			//时间转为小时/天数
			TimeTransformDay(Time){
				if(Time==null) return 0+"天";
				if(Time.length==0) return 0+"天";
				if(Time[0].length==0 || Time[1].length==0) return 0+"天";
				var Time1 = new Date(Time[0]);
				var Time2 = new Date(Time[1]);
				var getTime = Time2.getTime() - Time1.getTime();
				var Value = parseInt(getTime / (1000 * 60 * 60 * 24));
				if(Value==0)
				{
					Value = Math.ceil(getTime / (1000 * 60 * 60))+"小时";
				}
				else
				{
					Value = Value+"天";
				}
				return Value;
			},
			// 基础功能开关
			BasicSwitch(e){
				if(!e)
				{
					var companyauthority = this.Form.companyauthority;
					for(var i=0;i<companyauthority.length;i++)
					{
						if(companyauthority[i].authoritykey!="Basic")
						{
							companyauthority[i].authorityvalue = false;
						}
					}
				}
			},
			// 功能模块开关
			ModuleSwitch(e){
				if(e){
					var companyauthority = this.Form.companyauthority;
					for(var i=0;i<companyauthority.length;i++)
					{
						if(companyauthority[i].authoritykey=="Basic")
						{
							companyauthority[i].authorityvalue = true;
						}
					}
				}
			},
			//物业代码
			EID(){
				var myDate = new Date();
				var getFullYear = myDate.getFullYear();
				getFullYear = getFullYear.toString().substring(2,4)
				var getMonth = myDate.getMonth()+1;
				getMonth = getMonth>10?getMonth:'0'+getMonth;
				return getFullYear+getMonth;
			},
			//同意
			updateApplyStatus(){
				this.$refs.RuleForm.validate(async valid => {
					if (!valid) return;
					this.DialogButtonLoad = true;//显示按钮加载动画
					for(var i=0;i<this.Form.companyauthority.length;i++)
					{
						var Time = this.Form.companyauthority[i].Time;
						if(Time==null)
						{
							this.Form.companyauthority[i].Time = ['2020-01-01 00:00:00','2020-01-01 00:00:00'];
							break;
						}
						
						if(Time!=null)
						{
							var starttime = this.Form.companyauthority[i].Time[0];
							var endtime = this.Form.companyauthority[i].Time[1];
							if(starttime=='' || endtime=='')
							{
								this.Form.companyauthority[i].Time[0] = '2020-01-01 00:00:00';
								this.Form.companyauthority[i].Time[1] = '2020-01-01 00:00:00';
								break;
							}
						}
					}
					var {data: res} = await this.$http.post('updateApplyStatus', {Status:this.ststus,companyId:[],recordId:this.recordId,Form:this.Form});
					if (this.ReturnFiltration(res.code)) {
						if(res.result.Status=='1')
						{
							this.Message('已同意',"success");
							this.selectBsApply();
							this.DialogVisible = false;
						}
						else if(res.result.Status=='2')
						{
							this.Message('公司名称已存在',"warning");
						}
						else if(res.result.Status=='3')
						{
							this.Message('登录名已存在',"warning");
						}
						else
						{
							this.Message('操作失败',"error");
						}
					}
					this.DialogButtonLoad = false;//隐藏按钮加载动画
				})
			},
			// 返回过滤
			ReturnFiltration(code) {
				if (code == "100001") {
					//成功
					return true;
				} else if (code == "100002") {
					//Session过期
					this.Message('身份已过期，请重新登录', "warning");
					return false;
				} else if (code == "100003") {
					//校验失败
					this.Message('校验失败，请重新登录', "error");
					return false;
				} else if (code == "100004") {
					//格式不正确
					this.Message('请求参数不正确', "error");
					return false;
				} else if (code == "100005") {
					//操作失败
					this.Message('操作失败', "error");
					return false;
				}
			},
			//提醒消息
			Message(message, type) {
				this.$message({showClose: true,message: message,type: type,offset: "14"});
			}
		}
	}
</script>

<style scoped>
	.BsApply{
		width: 100%;
		height: 100%;
		border-radius: 10px;
		background-color: #FFFFFF;
		display: flex;
		flex-direction: column;
	}
	/* 头部 */
	.top{
		margin: 0px auto;
		width: 98%;
		height: 75px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.top>div{
		display: flex;
	}
	
	.top-left>div{
		margin-right: 15px;
	}
	
	.ConditionType{
		width: 200px;
	}
	
	.Condition{
		width: 250px;
	}
	
	.centre{
		flex: 1;
	}
	
	.Table{
		width: 98%;
		height: 100%;
		margin: 0px auto;
	}
	
	.Table-Attribute{
		overflow-x: auto;
	}
	
	.bottom{
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 15px;
		color: #909399;
		padding-left: 20px;
	}
	
	/* 同意弹框 */
	.Dialog-Form{
		height: 500px;
		overflow-y: auto;
	}
	
	.Dialog-Form-Left{
		width: 98%;
	}
	
	.Form-CompanyAuthority{
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.Form-CompanyAuthority-Switch{
		display: flex;
		align-items: center;
	}
	
	.Form-CompanyAuthority-Time{
		margin-left: 30px;
	}
</style>

<template>
	<div class="Home">
		<div class="Top">
			<div class="Top-Index1">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">公司数量 （软件激活）</div>
							<div class="Top-Left-Value">{{CsCompanyCount}}</div>
							<div class="Top-Left-describe">激活软件公司数量</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index1 flex-center">
								<i class="el-icon-box"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Top-Index2">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">公司数量 （云端）</div>
							<div class="Top-Left-Value">{{BsCompanyCount}}</div>
							<div class="Top-Left-describe">云端软件公司数量</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index2 flex-center">
								<i class="el-icon-cloudy"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Top-Index3">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">人员数量 （云端）</div>
							<div class="Top-Left-Value">{{BsEmployeesCount}}</div>
							<div class="Top-Left-describe">云端人员数量</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index3 flex-center">
								<i class="el-icon-user"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Top-Index4">
				<div>
					<div class="Top-Content">
						<div class="Top-Index-Left">
							<div class="Top-Left-Name">设备数量 （云端）</div>
							<div class="Top-Left-Value">{{BscontrollersCount}}</div>
							<div class="Top-Left-describe">云端设备数量</div>
						</div>
						<div class="Top-Index-right flex-center">
							<div class="Top-Index4 flex-center">
								<i class="el-icon-mobile-phone"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="Statistics">
			<div class="Statistics-Left">
				<div class="Statistics-Title">
					<div>今日验证 (云端)</div>
					<div class="Card-hint">
						<div>
							<i class="el-icon-remove" style="color: #409EFF;"></i>&nbsp;二维码
						</div>
						<div>
							<i class="el-icon-remove" style="color: #67C23A;"></i>&nbsp;IC卡
						</div>
						<div>
							<i class="el-icon-remove" style="color: #E6A23C;"></i>&nbsp;密码卡号
						</div>
						<div>
							<i class="el-icon-remove" style="color: #E94B73;"></i>&nbsp;人脸
						</div>
					</div>
				</div>
				<div id="homeCardRecord" class="homeCardRecord"></div>
			</div>
			<div class="Statistics-Right">
				<div id="homecontrollerType" class="homecontrollerType"></div>
			</div>
		</div>
		<div class="Bottom">
			<div class="Timeline flex-center">
				<div class="Timeline-content">
					<div v-for="(item,index) in HistoryRecord" :key="item.recordid" class="Timeline-Index">
						<div :class="RecordType(item.recordtype,'COlOR')">
							<i :class="RecordType(item.recordtype,'ICON')"></i>
						</div>
						<div class="Timeline-Index-content">
							<div class="Timeline-Index-Value">
								<div class="Timeline-Index-Title">{{item.title}}</div>
								<div class="Timeline-Value">{{item.description}}</div>
								<div class="Timeline-Index-DateTime">
									<div>时间</div>
									<div class="Timeline-DateTime">{{item.recorddatetime}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="Apply">
				<div class="Apply-Title flex-items-center">
					云端账户使用申请
					<div>{{tableData.length}}</div>
				</div>
				<div class="Apply-Table">
					<el-table :data="tableData" class="Apply-Table-Style">
						<el-table-column align="center" width="50">
							<template slot="header" slot-scope="scope">
							    <i class="el-icon-office-building Apply-Table-Icon"></i>
							</template>
							<template slot-scope="scope">
								<i class="el-icon-office-building Apply-Table-Icon"></i>
							</template>
						</el-table-column>
						<el-table-column prop="companyname" align="center" label="公司名称"></el-table-column>
						<el-table-column prop="name" align="center" label="联系人"></el-table-column>
						<el-table-column prop="telephone" align="center" label="手机号" width="120"></el-table-column>
						<el-table-column prop="applydattime" align="center" label="申请时间"></el-table-column>
						<el-table-column label="操作" align="center" width="150">
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" content="查看" placement="top" :enterable="false">
									<el-button type="primary" size="mini" icon="el-icon-tickets" @click="skip()"></el-button>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data (){
			return {
				//本地安装公司数
				CsCompanyCount:0,
				//云端公司数
				BsCompanyCount:0,
				//云端公司人数
				BsEmployeesCount:0,
				//云端公司设备数
				BscontrollersCount:0,
				//历史记录
				HistoryRecord:[],
				QueryBsApply: {
					Status:"1",//类型
					Condition: "", //条件值
					ConditionType: '1', //条件类型
					Count: 20, //条数
					Pages: 1, //页数
					SearchDateTime:[] //日期
				},
				//条数
				ApplyCount: 0,
				//使用申请
				tableData: []
		    }
	    },
		//完成html渲染后执行
		mounted(){
			//查询数量
			this.queryCsBsCount();
			//刷卡流量统计
			this.homeCardRecord();
			//查询设备占比
			this.homecontrollerTypeCount();
			//查询历史记入
			this.selectHistoryRecord();
			//查询申请
			this.selectBsApply();
		},
		methods: {
			//查询数量
			async queryCsBsCount(){
				try 
				{
					var {data: res} = await this.$http.post('queryCsBsCount');
					if (this.ReturnFiltration(res.code)) {
						this.CsCompanyCount=res.result.CsCompanyCount;
						this.BsCompanyCount=res.result.BsCompanyCount;
						this.BsEmployeesCount=res.result.BsEmployeesCount;
						this.BscontrollersCount=res.result.BscontrollersCount;
					}
				} 
				catch (e) 
				{
					this.$notify.error({title: '错误',message:e});
				}
			},
			//刷卡流量统计
			async homeCardRecord(){
				try {
					var {data: res} = await this.$http.post('homeCardRecord');
					if (this.ReturnFiltration(res.code)) 
					{
						var Record = res.result.cardRecord;
						let myCard = this.$echarts.init(document.getElementById('homeCardRecord'),'light');
						var option = {
							color: ['#409EFF','#67C23A','#E6A23C','#E94B73'],
							tooltip: {
							    trigger: 'axis'
							},
							grid: {
								left: '3%',
								top: '5%',
								right: '3%',
								bottom: '10%',
								containLabel: true
							},
							xAxis: {
						        type: 'category',
						        boundaryGap: false,
						        data: this.TimeQuantum()
						    },
						    yAxis: {
						        type: 'value',
								splitNumber: 3,
								splitLine: {
								    show: true,
								    lineStyle:{
								        type:'dashed'
								    }
								}
						    },
						    series: [
								{
									name:'二维码',
									smooth: true,
									data:this.CardCount(Record,"1"),
									type: 'line',
									areaStyle: {},
									symbolSize: 10,
									symbol: 'circle',
									lineStyle: {
										color: "#409EFF",
										width: 3,
									},
									areaStyle:{
										color:"rgba(246,248,253, 0.5)"
									}
								},
								{
									name:'IC卡',
									smooth: true,
									data:this.CardCount(Record,"2"),
									type: 'line',
									areaStyle: {},
									symbolSize: 10,
									symbol: 'circle',
									lineStyle: {
										color: "#67C23A",
										width: 3,
									},
									areaStyle:{
										color:"rgba(246,248,253, 0.5)"
									}
								},
								{
									name:'密码卡号',
									smooth: true,
									data:this.CardCount(Record,"3"),
									type: 'line',
									areaStyle: {},
									symbolSize: 10,
									symbol: 'circle',
									lineStyle: {
										color: "#E6A23C",
										width: 3,
									},
									areaStyle:{
										color:"rgba(246,248,253, 0.5)"
									}
								},
								{
									name:'人脸',
									smooth: true,
									data:this.CardCount(Record,"4"),
									type: 'line',
									areaStyle: {},
									symbolSize: 10,
									symbol: 'circle',
									lineStyle: {
										color: "#E94B73",
										width: 3,
									},
									areaStyle:{
										color:"rgba(246,248,253, 0.5)"
									}
								}
							]
						};
						myCard.setOption(option);
						//设置自动适应页面大小
						window.onresize = function(){
						    myCard.resize();
						}
					}
				} catch (e) {
					this.$notify.error({title: '错误',message:e});
				}
			},
			//时间段
			TimeQuantum(){
				var Time = [];
				for(var i=0;i<24;i++){
					var StartHH = i<10?'0'+i+":00":i.toString()+":00";
					var endHH = "";
					if(i==23)
					{
						endHH = "23:00";
					}
					else
					{
						endHH = (i+1)<10?'0'+(i+1)+":00":(i+1).toString()+":00";
					}
					Time[i] = StartHH+" - "+endHH;
				}
				return Time;
			},
			//刷卡次数
			CardCount(Record,Type){
				var Data = {};
				for(var i=0;i<Record.length;i++)
				{
					if(Record[i].cardType==Type)
					{
						Data = Record[i];
						break;
					}
				}
				var ReturnData = [];
				var HH = new Date().getHours()
				for(var i=0;i<24;i++)
				{
					if(i<=HH)
					{
						if(Data["time"+i]!=undefined)
						{
							ReturnData[ReturnData.length] = Data["time"+i];
						}
						else
						{
							ReturnData[ReturnData.length] = 0;
						}
					}
				}
				return ReturnData;
			},
			//查询设备占比
			async homecontrollerTypeCount(){
				try
				{
					var {data: res} = await this.$http.post('homecontrollerTypeCount');
					if (this.ReturnFiltration(res.code)) {
						var controllerCount = res.result.controllerTypeCount;
						let myChart = this.$echarts.init(document.getElementById('homecontrollerType'),'light');
						var option = {
							color: ['#409EFF','#23CCB3','#FAAE42','#FF6E40','#D63384','#6610F2'],
							title: {
						        text: '设备类型占比',
								subtext: '云端设备类型数量占比',
						        left: 'center',
								top:'6%',
								textStyle:{
									fontSize: 15,
								}
						    },
						    tooltip: {
						        trigger: 'item'
						    },
						    legend: {
						        top: '85%',
						        left: 'center'
						    },
						    series: [
						        {
						            name: '设备数量',
						            type: 'pie',
						            radius: ['40%', '60%'],
									avoidLabelOverlap: false,
									itemStyle: {
										borderRadius: 5,
										borderColor: '#fff',
										borderWidth: 2
									},
									label: {
										show: false,
										position: 'center'
									},
									labelLine: {
										show: false
									},
						            data:this.controllerType(controllerCount)
						        }
						    ]
						};
						myChart.setOption(option);
						//设置自动适应页面大小
						window.onresize = function(){
						    myChart.resize();
						}
					}
				} catch (e) {
					this.$notify.error({title: '错误',message:e});
				}
				
			},
			//设备类型占比
			controllerType(controller){
				var Data = [];
				for(var i=0;i<controller.length;i++)
				{
					Data[i] = {name:controller[i].controllersubtype,value:controller[i].count}
				}
				return Data;
			},
			//查询历史记入
			async selectHistoryRecord(){
				try 
				{
					var {data: res} = await this.$http.post('selectHistoryRecord');
					if (this.ReturnFiltration(res.code)) {
						this.HistoryRecord=res.result.HistoryRecord;
					}
				} 
				catch (e) 
				{
					this.$notify.error({title: '错误',message:e});
				}
			},
			//记录类型
			RecordType(recordtype,Type){
				if(recordtype==1) 
				{
					//权限更新
					return Type=="ICON"?"el-icon-refresh":"Timeline-Index-Icon flex-center TimelineType1";
				}
				else if(recordtype==2)
				{
					//软件激活
					return Type=="ICON"?"el-icon-key":"Timeline-Index-Icon flex-center TimelineType2";
				}
				else if(recordtype==3)
				{
					//使用申请
					return Type=="ICON"?"el-icon-s-order":"Timeline-Index-Icon flex-center TimelineType3";
				}
			},
			// 查询申请
			async selectBsApply() {
				try {
					var {data: res} = await this.$http.post('selectBsApply', this.QueryBsApply);
					if (this.ReturnFiltration(res.code)) {
						this.tableData = res.result.Query;
						this.ApplyCount = res.result.Count; //条数
					}
				} catch (e) {
					this.$notify.error({title: '错误',message: e});
				}
			},
			//跳转到申请界面
			skip(){
				this.$router.push('/BsApply');//跳转
			},
			// 返回过滤
			ReturnFiltration(code) {
				if (code == "100001") {
					//成功
					return true;
				} else if (code == "100002") {
					//Session过期
					this.Message('身份已过期，请重新登录', "warning");
					return false;
				} else if (code == "100003") {
					//校验失败
					this.Message('校验失败，请重新登录', "error");
					return false;
				} else if (code == "100004") {
					//格式不正确
					this.Message('请求参数不正确', "error");
					return false;
				} else if (code == "100005") {
					//操作失败
					this.Message('操作失败', "error");
					return false;
				}
			},
			//提醒消息
			Message(message, type) {
				this.$message({showClose: true,message: message,type: type,offset: "14"});
			}
	  	}
	}
</script>

<style scoped>
	/* 头部数据 */
	.Top{
		width: 100%;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.Top>div{
		width: 24%;
		height: 100%;
		display: flex;
		border-radius: 12px;
		justify-content: flex-end;
	}
	
	.Top>div>div{
		width: 98.5%;
		height: 100%;
		border-radius: 10px;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.Top-Content{
		width: 90%;
		height: 90%;
		display: flex;
		justify-content: space-between;
	}
	
	.Top-Index-Left{
		height: 100%;
	}
	
	.Top-Left-Name{
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #636363;
		height: 30%;
	}
	
	.Top-Left-Value{
		display: flex;
		align-items: center;
		font-size: 40px;
		font-weight: 700;
		height: 40%;
	}
	
	.Top-Left-describe{
		display: flex;
		align-items: center;
		font-size: 13px;
		color: #959595;
		height: 30%;
	}
	
	.Top-Index-right{
		height: 100%;
	}
	
	.Top-Index-right>div{
		width: 65px;
		height: 65px;
		border-radius: 50%;
	}
	
	.Top-Index-right>div>i{
		color: #FFFFFF;
		font-size: 30px;
	}
	
	.Top-Index1{
		background-color: #23CCB3;
		color: #23CCB3;
	}
	
	.Top-Index2{
		background-color: #409EFF;
		color: #409EFF;
	}
	
	.Top-Index3{
		background-color: #8354C8;
		color: #8354C8;
	}
	
	.Top-Index4{
		background-color: #E94B73;
		color: #E94B73;
	}
	
	.Statistics{
		margin-top: 20px;
		height: 420px;
		display: flex;
		justify-content: space-between;
	}
	
	.Statistics-Left{
		width: 74.6%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 10px;
	}
	.Statistics-Right{
		width: 23.8%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 10px;
	}
	
	.Statistics-Title{
		height: 14%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding:0px 25px;
		font-size: 15px;
		color:#666666;
		font-weight: 700;
		border-radius: 10px 10px 0px 0px;
	}
	
	.Card-hint{
		display: flex;
		align-items: center;
	}
	
	.Card-hint>div{
		display: flex;
		align-items: center;
		font-size: 14px;
		margin: 0px 10px;
		color: #909399;
		font-weight: 500;
	}
	
	.Card-hint i{
		font-size: 20px;
		margin-top: 1px;
	}
	
	.homeCardRecord{
		width: 100%;
		height: 86%;
	}
	
	.homecontrollerType{
		width: 100%;
		height: 100%;
	}
	
	.Bottom{
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
	
	.Timeline{
		width: 40%;
		height: 1080px;
		border-radius: 10px;
		overflow:hidden;
		background-color: #FFFFFF;
	}
	
	.Timeline-content{
		overflow-y:scroll;
		margin-right: -65px;
		height: 92%;
		width: 97%;
	}
	
	.Timeline-Index{
		display: flex;
		width: 100%;
	}
	
	.Timeline-Index-Icon{
		position: relative;
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	
	.TimelineType1{
		background-color: #23CCB3;
	}
	
	.TimelineType2{
		background-color: #409EFF;
	}
	
	.TimelineType3{
		background-color: #1C84C6;
	}
	
	.Timeline-Index-Icon>i{
		color: #FFFFFF;
		font-size: 25px;
	}
	
	.Timeline-Index-content{
		width: 100%;
		margin-top: 4px;
		margin-left: -28px;
		border-left:6px solid #E7EAEC;
	}
	
	.Timeline-Index-Value{
		float: right;
		width: 85%;
		height: 100%;
		margin-right: 30px;
	}
	
	.Timeline-Index-Value>div{
		margin: 15px 0px;
	}
	
	.Timeline-Index-Title{
		font-size: 20px;
		color: #606266;
	}
	
	.Timeline-Value{
		font-size: 15px;
		color: #606266;
	}
	
	.Timeline-Index-DateTime{
		font-size: 15px;
		padding-bottom: 40px;
		color: #606266;
	}
	
	.Timeline-Index-DateTime>div{
		margin: 5px 0px;
	}
	
	.Timeline-DateTime{
		color: #23B7E5;
		font-size: 14px;
	}
	
	/* 申请列表 */
	.Apply{
		width: 58.7%;
		border-radius: 10px;
		background-color: #FFFFFF;
	}
	
	.Apply-Title{
		height: 65px;
		padding-left: 25px;
		font-size: 15px;
		color: #58666E;
		font-weight: 700;
	}
	
	.Apply-Title>div{
		padding: 1px 12px;
		margin-left: 10px;
		border-radius: 3px;
		font-size: 14px;
		color: #FFFFFF;
		background-color: #409EFF;
	}
	
	.Apply-Table{
		height: 980px;
		margin: 10px auto;
		width: 95%;
		overflow-y: auto;
	}
	
	.Apply-Table-Icon{
		font-size: 17px;
		/* color: #409EFF; */
	}
	
	.Apply-Table-Style{
		
	}
</style>

<template>
	<div class="Index">
		<el-container>
			<!-- 头部 -->
			<el-header height="65px" class="Top">
				<!-- 左侧 -->
				<div>
					<div class="Name flex-center">
						ISCS 2.0 软件授权管理
					</div>
					<div class="Breadcrumb">
						{{MenuTitle}} <i class="el-icon-arrow-right"></i> {{MenuIndex}}
					</div>
				</div>
				<!-- 右侧 -->
				<div class="Top-Right">
					<div class="Right-Icon">
						<i class="el-icon-switch-button" @click="logout"> 退出</i>
					</div>
				</div>
			</el-header>
			<el-container class="Container">
				<!-- 左侧菜单 -->
				<el-aside width="221px" class="Left">
					<div v-for="(itemTitle,index) in Menu" :key="'1'+index" class="Menu-Index">
						<div class="Menu-title">{{ itemTitle.title }}</div>
						<div v-for="(item) in itemTitle.arrMenu" :key="'2'+item.Icon" class="arrMenu-Index"  @click="MenuSwitch(item,itemTitle)">
							<div :class="item.Name==MenuIndex?'Menu-Buuton2 ':'Menu-Buuton1'">
								<i :class="item.Icon" class="Menu-icon"></i>{{ item.Name }}
							</div>
						</div>
					</div>
				</el-aside>
				<!-- 内容主体 -->
				<el-main class="Right">
					<transition name="slide-fade">
						<router-view></router-view>
					</transition>
				</el-main>
			</el-container>
		</el-container>
		<!-- 右侧抽屉 -->
		<el-drawer :with-header="false" :modal="false" :visible.sync="drawer" size="330px">
			<div class="drawer_from">
				<div class="drawer_from_title">
					通知面板
				</div>
				<div class="Message flex-center">
					<div>暂无消息</div>
				</div>
				<el-card shadow="hover" class="OS">
					<div class="OSDetails flex-center">
						<div>
							<div class="OSDetails-Index-Title">
								<h3>系统信息</h3>
							</div>
							<div class="OSDetails-Index">系统版本号: 1.0.0.210526</div>
						</div>
					</div>
				</el-card>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: 'Index',
		data (){
			return {
				// 左侧菜单
				Menu:[
					{
						title:"云端管理",
						arrMenu:[
							{"Name":"云端数据","Icon":"el-icon-data-analysis","Url":"/BsStatistics"},
							{"Name":"云端公司","Icon":"el-icon-office-building","Url":"/BsCompany"},
						]
					},
					{
						title:"客户安装",
						arrMenu:[
							{"Name":"数据统计","Icon":"el-icon-data-line","Url":"/CsStatistics"},
							{"Name":"本地安装","Icon":"el-icon-box","Url":"/CsCompany"},
						]
					},
					{
						title:"使用申请",
						arrMenu:[
							{"Name":"申请记录","Icon":"el-icon-chat-line-square","Url":"/BsApply"}
						]
					},
					{
						title:"系统设置",
						arrMenu:[
							{"Name":"操作记录","Icon":"el-icon-mouse","Url":"/OperationLog"}
						]
					}
					
				],
				//右侧抽屉
				drawer:false,
				// 菜单所属
				MenuTitle:"云端管理",
				// 选中的菜单
				MenuIndex:"云端数据"
			}
		},
		mounted(){
			//默认选中的菜单
			var MenuTitle = window.sessionStorage.getItem('MenuTitle');
			var MenuIndex = window.sessionStorage.getItem('MenuIndex');
			if(MenuIndex!=null && MenuIndex!=undefined)
			{
				this.MenuTitle = MenuTitle;
				this.MenuIndex = MenuIndex;
			}
		},
		watch:{
			//地址栏变化
			$route(to,from){
				var Url = to.path;
				for(var i=0;i<this.Menu.length;i++){
					if(this.Menu[i].Url==Url)
					{
						this.MenuIndex = this.Menu[i].Name;
					}
				}
			}
		},
		methods: {
			//全屏/退出全屏
			handleFullScreen: function() {
				let element = document.documentElement;
				// 判断是否已经是全屏
				// 如果是全屏，退出
				if (this.fullscreen) {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					}
				} else { // 否则，进入全屏
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen();
					} else if (element.mozRequestFullScreen) {
						element.mozRequestFullScreen();
					} else if (element.msRequestFullscreen) {
						// IE11
						element.msRequestFullscreen();
					}
				}
				this.fullscreen = !this.fullscreen;
			},
			
			//注销
			async logout() {
				try
				{
					//清空本地信息
					window.sessionStorage.clear();
					this.$router.push('/Login')
					//注销服务端session
					var {data: res} = await this.$http.post('logout');
				}
				catch(e)
				{
					//清空本地信息
					window.sessionStorage.clear();
					this.$router.push('/Login')
				}
				
			},
			//菜单切换
			MenuSwitch(item,itemTitle){
				if(item.Name!=this.MenuIndex)
				{
					this.MenuTitle = itemTitle.title;
					this.MenuIndex = item.Name;
					window.sessionStorage.setItem('MenuTitle', itemTitle.title);
					window.sessionStorage.setItem('MenuIndex', item.Name);
					this.$router.push(item.Url);
				}
			}
		},
	}
</script>

<style scoped>
	.Index{
		width: 100%;
		height: 100%;
	}
	
	/* 外层容器 */
	.el-container {
		width: 100%;
		height: 100%;
		background-color: #EDF0F5;
	}
	
	/* 头部 */
	.Top{
		padding: 0px;
		display: flex;
		justify-content: space-between;
		background-color: #FFFFFF;
		box-shadow: 0 0.5rem 1rem #2c33491a;
		z-index: 1;
	}
	
	/* 头部内容 */
	.Top>div{
		height: 100%;
		display: flex;
		align-items: center;
	}
	
	/* 系统名 */
	.Name{
		width: 220px;
		height: 100%;
		color: #606266;
		font-weight: 700;
		font-size: 18px;
		margin-right: 20px;
		border-right: 1px solid #EBEEF5;
	}
	
	/* 左侧图标按钮 */
	.Left-Icon{
		margin: 20px;
		font-size: 18px;
		color: #606266;
		cursor:pointer;
	}
	
	.Breadcrumb{
		/* margin: 20px; */
		font-size: 14px;
		color: #606266;
	}
	
	.Top-Right{
		margin-right: 10px;
	}
	
	/* 右侧图标按钮 */
	.Right-Icon{
		margin: 30px;
		font-size: 15px;
		color: #606266;
		cursor:pointer;
	}
	
	/* 左侧抽屉 */
	.Left{
		background-color: #FFFFFF;
		border-right: 1px solid #E8E8E8;
	}
	
	.Menu-Index{
		padding: 0px 10px;
	}
	.Menu-title{
		font-size: 13px;
		color: #909399;
		margin: 20px 15px;
		margin-bottom: 8px;
	}
	.arrMenu-Index{
		font-size: 14px;
		cursor:pointer;
	}
	
	/* 菜单按钮 */
	.Menu-Buuton1{
		padding: 8px 15px;
		margin: 5px auto;
		border-radius: 5px;
		color: #303133;
		display: flex;
		align-items: center;
	}
	
	.Menu-Buuton1:hover{
		color: #409EFF;
		background-color: #E2EDFF;
	}
	
	.Menu-Buuton2{
		padding: 8px 15px;
		margin: 5px auto;
		border-radius: 5px;
		color: #409EFF;
		display: flex;
		align-items: center;
		background-color: #E2EDFF;
	}

	.Menu-icon{
		font-size: 18px;
		margin-right: 10px;
	}
	
	.Container{
		overflow: auto;
	}
	
	.Right{
		overflow-y: auto;
		overflow-x: hidden;
	}
	
	/* 抽屉布局 */
	.drawer_from {
		margin: 0px auto;
		width: 90%;
		height: 100%;
		font-size: 14px;
		display: flex;
		flex-direction: column;
	}

	/* 抽屉标题 */
	.drawer_from_title {
		height: 60px;
		display: flex;
		align-items: center;
		color: #333;
		font-size: 14px;
	}
	
	.Message{
		flex: 1;
		color: #8C939D;
	}
	
	.OS{
		margin: 20px 0px;
	}
	
	.OSDetails{
		height:130px;
	}
	
	.OSDetails-Index-Title{
		display:flex;
		justify-content: center;
		color: #409EFF;
	}
	
	.OSDetails-Index{
		display:flex;
		justify-content: center;
		color: #8C939D;
		font-size: 14px;
	}
	
	/* 页面切换动画 */
	.slide-fade{
	 position: absolute;
	 left:0;
	 right: 0;
	}
	
	.slide-fade-enter-active {
	 transition: all 1s ease;
	}
	
	.slide-fade-leave-active {
	 transition: all .1s cubic-bezier(2.0, 0.5, 0.8, 1.0);
	}
	
	.slide-fade-enter, .slide-fade-leave-to{
	 left:0;
	 right: 0;
	 transform: translateX(30px);
	 opacity: 0;
	}
</style>

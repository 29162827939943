import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import './plugins/element.js';
import './assets/css/Global.css';
import * as echarts from 'echarts';
import { message } from './config/ResetMessage';

Vue.config.productionTip = false;

//axios请求的根路径
var ServerUrl = window.global_config.ServerUrl;
Vue.prototype.$ServerUrl = ServerUrl;
axios.defaults.baseURL = ServerUrl;
axios.defaults.withCredentials = true;
Vue.prototype.$http = axios;

//Bs图片地址
var BsImageUrl = window.global_config.BsImageUrl;
Vue.prototype.$BsImageUrl = BsImageUrl;

//Cs图片地址
var CsImageUrl = window.global_config.CsImageUrl;
Vue.prototype.$CsImageUrl = CsImageUrl;

//ISCS2.0软件地址
var LoginUrl = window.global_config.LoginUrl;
Vue.prototype.$LoginUrl = LoginUrl;

//echarts图表统计UI
Vue.prototype.$echarts = echarts;

//消息框
Vue.prototype.$message = message;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

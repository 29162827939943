<template>
	<div class="BsCompany" v-loading="CompanyLoadStatus">
		<div v-if="companyId == 0" style="background-color: #FFFFFF;border-radius: 10px;">
			<div class="cardTitle">公司列表</div>
			<div class="top">
				<div class="top-left">
					<div>
						<div class="search_item">公司名称</div>
						<div class="search_value"><el-input placeholder="请输入" v-model="QueryCompany.CompanyName"></el-input>
						</div>
					</div>
					<div>
						<div class="search_item">联系人名称</div>
						<div class="search_value"><el-input placeholder="请输入" v-model="QueryCompany.ContactName"></el-input>
						</div>
					</div>
					<div>
						<div class="search_item">联系人电话</div>
						<div class="search_value"><el-input placeholder="请输入"
								v-model="QueryCompany.ContactPhone"></el-input></div>
					</div>
				</div>
				<div class="top-right">
					<el-button size="small" icon="el-icon-search" type="primary" @click="QueryBs_Company">查询</el-button>
					<el-button size="small" icon="el-icon-refresh-left" @click="reset">重置</el-button>
				</div>
			</div>
			<div class="divider"></div>
			<div class="centre">
				<div class="operation">
					<div>
						<el-button size="small" style="margin-right: 10px;" icon="el-icon-circle-plus-outline"
							type="primary" @click="AddDialog()">添加公司</el-button>
						<el-popconfirm title="确定要删除选中的公司吗？" @confirm="deleteBsCompany()">
							<el-button size="small" slot="reference" icon="el-icon-delete" type="danger">删除公司</el-button>
						</el-popconfirm>
					</div>
					<div>
						<el-button size="small" type="info" icon="el-icon-refresh-right"
							@click="QueryBs_Company">刷新</el-button>
					</div>
				</div>
				<div class="Table">
					<el-table :data="tableData" class="Table-Attribute" highlight-current-row
						@selection-change="TableCheckbox" :header-cell-style="{ 'background-color': '#F2F3F5' }"
						height="100%">
						<el-table-column type="selection" align="center"></el-table-column>
						<el-table-column prop="companyname" align="" label="公司名称"></el-table-column>
						<el-table-column prop="contact" align="" label="联系人"></el-table-column>
						<el-table-column prop="telephone" align="" label="手机号"></el-table-column>
						<el-table-column prop="authorizationstarttime" align="" label="有效开始时间"></el-table-column>
						<el-table-column prop="authorizationendtime" align="" label="有效结束时间"></el-table-column>
						<el-table-column align="" fixed="right" width="175" label="操作">
							<template slot-scope="scope">
								<el-tooltip effect="dark" content="查看详情" placement="top">
									<el-button type="success" size="mini" icon="el-icon-tickets"
										@click="Details(scope.row)"></el-button>
								</el-tooltip>
								<el-tooltip effect="dark" content="修改信息" placement="top">
									<el-button type="primary" size="mini" icon="el-icon-edit"
										@click="UpdateDialog(scope.row)"></el-button>
								</el-tooltip>
								<el-tooltip effect="dark" content="删除公司" placement="top">
									<el-button type="danger" size="mini" icon="el-icon-delete"
										@click="deleteBsCompany2(scope.row.companyid)"></el-button>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="bottom">
				共 {{ CompanyCount }} 条 <el-pagination background layout="prev, pager, next" @current-change="CurrentChange"
					:page-size="20" :total="CompanyCount"></el-pagination>
			</div>
		</div>
		<div v-if="companyId != 0">
			<div class="top" style="background-color: #FFFFFF;border-radius: 10px;">
				<div class="top-left">
					<el-page-header @back="companyId = 0" content="详情页面"></el-page-header>
				</div>
				<div class="top-right">
					<el-button size="small" icon="el-icon-refresh-right" @click="Details()">刷新</el-button>
				</div>
			</div>
			<div class="centre" style="margin-top: 20px;border-radius: 10px;">
				<div class="centre-value">
					<div class="centre-left">
						<div style="margin:20px">
							<div class="CompanyLogo flex-center">
								<img v-if="Form.companylogo != ''" :src="Form.companylogo" />
								<img v-else src="../assets/images/shield.png" />
							</div>
							<h2><i class="el-icon-office-building"></i> {{ Form.companyname }}</h2>
							<div class="CompanyNote">
								公司简介：{{ Form.companynote == "" ? "无" : Form.companynote }}
							</div>
							<div class="Company-value">
								<div class="Company-Index">
									<div class="Company-Index-Title">联系人</div>
									<div class="Company-Index-Value">{{ Form.name }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">手机号</div>
									<div class="Company-Index-Value">{{ Form.telephone }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">邮箱</div>
									<div class="Company-Index-Value">{{ Form.email }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">授权账户数</div>
									<div class="Company-Index-Value">{{ Form.authorizationaccount }}个</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">限制人员数</div>
									<div class="Company-Index-Value">{{ Form.personNum }} 个</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">限制设备数</div>
									<div class="Company-Index-Value">{{ Form.equipmentNum }} 台</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">加密密钥</div>
									<div class="Company-Index-Value">{{ Form.secretkey }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">信用代码</div>
									<div class="Company-Index-Value">{{ Form.companycode }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">物业代码</div>
									<div class="Company-Index-Value">{{ Form.eid }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">读卡扇区</div>
									<div class="Company-Index-Value">{{ Form.icseradd }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">扇区密钥</div>
									<div class="Company-Index-Value">{{ Form.icserkey }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">授权开始时间</div>
									<div class="Company-Index-Value">{{ Form.authorizationstarttime }}</div>
								</div>
								<div class="Company-Index">
									<div class="Company-Index-Title">授权结束时间</div>
									<div class="Company-Index-Value">{{ Form.authorizationendtime }}</div>
								</div>
								<div v-for="(item, index) in companyAuthority" :key="item.authoritykey"
									:class="item.authorityvalue == 1 ? 'Company-Index' : ''">
									<div v-if="item.authorityvalue == 1" class="Company-Index-Item">
										<div class="Company-Index-Title">{{ item.authoritydescribe }}</div>
										<div class="Company-Index-Value">
											<el-progress :percentage="EffectiveProportion(item)"
												:color="customColors"></el-progress>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="centre-right">
						<div>
							<div class="centre-right-top">
								<div class="Top-Index1">
									<div>
										<div class="Top-Content">
											<div class="Top-Index-Left">
												<div class="Top-Left-Name">人员数量 （总数）</div>
												<div class="Top-Left-Value">{{ companyDetails.EmployeesCount }}</div>
												<div class="Top-Left-describe">公司总人员数</div>
											</div>
											<div class="Top-Index-right flex-center">
												<div class="Top-Index1 flex-center">
													<i class="el-icon-user"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="Top-Index2">
									<div>
										<div class="Top-Content">
											<div class="Top-Index-Left">
												<div class="Top-Left-Name">设备数量 （在线）</div>
												<div class="Top-Left-Value">{{ companyDetails.ControllersCount }}</div>
												<div class="Top-Left-describe">当前在线设备数</div>
											</div>
											<div class="Top-Index-right flex-center">
												<div class="Top-Index2 flex-center">
													<i class="el-icon-mobile-phone"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="Top-Index3">
									<div>
										<div class="Top-Content">
											<div class="Top-Index-Left">
												<div class="Top-Left-Name">刷卡数 （今日）</div>
												<div class="Top-Left-Value">{{ companyDetails.BrushcardAcsCount }}</div>
												<div class="Top-Left-describe">今日刷卡总数</div>
											</div>
											<div class="Top-Index-right flex-center">
												<div class="Top-Index3 flex-center">
													<i class="el-icon-postcard"></i>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="equipment">
								<div class="equipment-Title">
									<div>设备占比</div>
								</div>
								<div class="equipment-Value">
									<div v-for="(item, index) in ControllerType" :key="item.controllersubtype">
										<div class="equipment-Type">{{ item.controllersubtype }}</div>
										<div class="equipment-Type-value"><el-progress :text-inside="true"
												:stroke-width="20"
												:percentage="Percentage(item.count, ControllerCount)"></el-progress></div>
									</div>
								</div>
							</div>
							<div class="Card">
								<div class="Card-Title">
									<div>今日验证</div>
									<div class="Card-hint">
										<div style="color: #409EFF;">
											<i class="el-icon-remove"></i>&nbsp;二维码
										</div>
										<div style="color: #67C23A;">
											<i class="el-icon-remove"></i>&nbsp;IC卡
										</div>
										<div style="color: #E6A23C;">
											<i class="el-icon-remove"></i>&nbsp;密码卡号
										</div>
										<div style="color: #E94B73;">
											<i class="el-icon-remove"></i>&nbsp;人脸
										</div>
									</div>
								</div>
								<div id="myCard" class="Graph1"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :title="DialogTitle" :visible.sync="DialogVisible" :close-on-click-modal="false" width="60%">
			<div class="Dialog-Form">
				<div class="Dialog-Form-Left">
					<el-form ref="RuleForm" :rules="Rules" :model="Form" label-width="100px">
						<el-form-item label="公司名称" prop="companyname">
							<el-input maxlength="32" suffix-icon="el-icon-school" show-word-limit v-model="Form.companyname"
								placeholder="请输入公司名称"></el-input>
						</el-form-item>
						<el-form-item label="公司代码" prop="companycode">
							<el-input maxlength="64" suffix-icon="el-icon-notebook-2" show-word-limit
								v-model="Form.companycode" placeholder="请输入公司代码"></el-input>
						</el-form-item>
						<el-form-item label="联系人" prop="name">
							<el-input maxlength="32" suffix-icon="el-icon-s-custom" show-word-limit v-model="Form.name"
								placeholder="请输入联系人名称"></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="telephone">
							<el-input maxlength="11" suffix-icon="el-icon-phone-outline" show-word-limit
								v-model="Form.telephone" placeholder="请输入联系人手机号"></el-input>
						</el-form-item>
						<el-form-item label="邮箱" prop="email">
							<el-input maxlength="32" suffix-icon="el-icon-message" show-word-limit v-model="Form.email"
								placeholder="请输入联系人邮箱"></el-input>
						</el-form-item>
						<el-form-item label="限制人员数" prop="personNum">
							<el-input maxlength="11" suffix-icon="el-icon-user-solid" show-word-limit
								v-model="Form.personNum" placeholder="请输入限制人员数"></el-input>
						</el-form-item>
						<el-form-item label="限制设备数" prop="equipmentNum">
							<el-input maxlength="11" suffix-icon="el-icon-receiving" show-word-limit
								v-model="Form.equipmentNum" placeholder="请输入限制设备数"></el-input>
						</el-form-item>
						<el-form-item label="登录名" prop="username">
							<el-input maxlength="32" suffix-icon="el-icon-user" show-word-limit v-model="Form.username"
								placeholder="请输入最高管理员登录名"></el-input>
						</el-form-item>
						<el-form-item label="登录密码" prop="userpassword">
							<el-input maxlength="32" suffix-icon="el-icon-lock" show-password v-model="Form.userpassword"
								placeholder="请输入最高管理员登录密码"></el-input>
						</el-form-item>
						<el-form-item label="高级配置">
							<el-switch v-model="AdvancedSetup" active-color="#13ce66" inactive-text="关闭"
								active-text="开启"></el-switch>
						</el-form-item>
						<div v-if="AdvancedSetup">
							<el-form-item label="授权账户数" prop="authorizationaccount">
								<el-input maxlength="3" suffix-icon="el-icon-user-solid" show-word-limit
									v-model="Form.authorizationaccount" placeholder="请输入授权账户数"></el-input>
							</el-form-item>
							<el-form-item label="二维码密钥" prop="secretkey">
								<el-input maxlength="32" suffix-icon="el-icon-key" show-password show-word-limit
									v-model="Form.secretkey" placeholder="请输入二维码密钥"></el-input>
							</el-form-item>
							<el-form-item label="物业代码" prop="eid">
								<el-input maxlength="4" suffix-icon="el-icon-office-building" show-word-limit
									v-model="Form.eid" placeholder="请输入物业代码"></el-input>
							</el-form-item>
							<el-form-item label="读卡扇区" prop="icseradd">
								<el-input maxlength="2" suffix-icon="el-icon-menu" show-word-limit v-model="Form.icseradd"
									placeholder="请输入读卡扇区号"></el-input>
							</el-form-item>
							<el-form-item label="扇区密钥" prop="icserkey">
								<el-input maxlength="12" suffix-icon="el-icon-key" show-password show-word-limit
									v-model="Form.icserkey" placeholder="请输入扇区密钥"></el-input>
							</el-form-item>
							<el-form-item v-for="(item, index) in Form.companyauthority" :key="item.authoritykey"
								:label="item.authoritydescribe">
								<div class="Form-CompanyAuthority">
									<div class="Form-CompanyAuthority-Switch">
										<el-switch v-if="item.authoritykey == 'Basic'" v-model="item.authorityvalue"
											@change="BasicSwitch" active-color="#13ce66" inactive-text="关闭"
											active-text="开启"></el-switch>
										<el-switch v-if="item.authoritykey != 'Basic'" v-model="item.authorityvalue"
											@change="ModuleSwitch" active-color="#13ce66" inactive-text="关闭"
											active-text="开启"></el-switch>
									</div>
									<div class="Form-CompanyAuthority-Time">
										<el-tag type="info">有效期 {{ TimeTransformDay(item.Time) }}</el-tag>&nbsp;&nbsp;&nbsp;
										<el-date-picker :disabled="!item.authorityvalue" type="datetimerange"
											:default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
											range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
											v-model="Form.companyauthority[index].Time"></el-date-picker>
									</div>
								</div>
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button :loading="DialogButtonLoad" type="primary" @click="addUpdateBsCompany()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "Company",
	data() {
		//校验手机号
		var checkTelephone = async (rule, value, callback) => {
			if (!value) {
				return callback(new Error('手机号不能为空'));
			}

			//校验格式
			var myreg = /^[0-9]*$/;
			if (!myreg.test(value)) {
				return callback(new Error('手机号格式不正确'));
			}
		};
		//校验邮箱格式
		var checkEmail = async (rule, value, callback) => {
			//校验邮箱格式
			if (value != "") {
				var myreg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
				if (!myreg.test(value)) {
					return callback(new Error('邮箱格式不正确'));
				}
			}
		};
		var checkPersonNum = async (rule, value, callback) => {
			if (!value) {
				return callback(new Error('限制人员数不能为空'));
			}
			//校验格式
			var myreg = /^[0-9]*$/;
			if (!myreg.test(value)) {
				return callback(new Error('格式不正确'));
			}
		};
		var checkEquipmentNum = async (rule, value, callback) => {
			if (!value) {
				return callback(new Error('限制设备数不能为空'));
			}
			//校验格式
			var myreg = /^[0-9]*$/;
			if (!myreg.test(value)) {
				return callback(new Error('格式不正确'));
			}
		};
		//校验账户数
		var checkauthorizationaccount = async (rule, value, callback) => {
			if (!value) {
				return callback(new Error('授权账户数不能为空'));
			}

			//校验格式
			var myreg = /^[0-9]*$/;
			if (!myreg.test(value)) {
				return callback(new Error('格式不正确'));
			}
		};
		//校验扇区号
		var checkicseradd = async (rule, value, callback) => {
			if (!value) {
				return callback(new Error('扇区号不能为空'));
			}

			//校验格式
			var myreg = /^[0-9]*$/;
			if (!myreg.test(value)) {
				return callback(new Error('格式不正确'));
			}
			//校验格式
			if (value < 0 || value > 15) {
				return callback(new Error('格式不正确'));
			}
		};
		return {
			// 公司列表加载状态
			CompanyLoadStatus: false,
			QueryCompany: {
				CompanyName: "", //公司名称
				ContactName: '', //联系人名称
				ContactPhone: '', //联系人电话
				Count: 20, //条数
				Pages: 1, //页数
			},
			//条数
			CompanyCount: 0,
			// 公司信息
			tableData: [],
			//公司权限
			companyAuthority: [],
			//公司id
			companyId: 0,
			//授权功能进度条
			customColors: [
				{ color: '#5cb87a', percentage: 20 },
				{ color: '#1989fa', percentage: 40 },
				{ color: '#6f7ad3', percentage: 60 },
				{ color: '#e6a23c', percentage: 80 },
				{ color: '#F56C6C', percentage: 100 }
			],
			//新增修改对话框标题
			DialogTitle: "",
			//新增修改对话框显示状态
			DialogVisible: false,
			//新增修改按钮加载状态
			DialogButtonLoad: false,
			//高级设置
			AdvancedSetup: false,
			//表单信息
			Form: {
				companyid: 0,//公司ID
				companyname: "",//公司名称
				companycode: "",//公司代码
				companylogo: "",//公司Logo
				companynote: "",//公司简介
				authorizationstarttime: "",//授权开始时间
				authorizationendtime: "",//授权结束时间
				name: "",//联络人名称
				telephone: "",//联络人电话
				email: "",//邮箱
				personNum: 0,//限制人员数
				equipmentNum: 0,//限制设备数
				username: "",//登录名
				userpassword: "",//登录密码
				authorizationaccount: "10",//授权账户数
				secretkey: "CBAB2018AP@#",//二维码密钥
				eid: this.EID(),//物业代码
				icseradd: "8",//读卡扇区
				icserkey: "FFFFFFFFFFF0",//扇区密钥
				companyauthority: [] //权限
			},
			//表单校验
			Rules: {
				companyname: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
				name: [{ required: true, message: '请输入联系人名称', trigger: 'blur' }],
				telephone: [{ required: true, validator: checkTelephone, trigger: 'blur' }],
				email: [{ validator: checkEmail, trigger: 'blur' }],
				username: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
				userpassword: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
				authorizationaccount: [{ required: true, validator: checkauthorizationaccount, trigger: 'blur' }],
				secretkey: [{ required: true, message: '请输入二维码密钥', trigger: 'blur' }],
				eid: [{ required: true, message: '请输入物业代码', trigger: 'blur' }],
				icseradd: [{ required: true, validator: checkicseradd, trigger: 'blur' }],
				icserkey: [{ required: true, message: '请输入扇区密钥', trigger: 'blur' }]
			},
			//勾选的公司
			TableCheckboxArray: [],
			//详情信息
			companyDetails: {
				EmployeesCount: 0,//人员数
				ControllersCount: 0,//设备数
				BrushcardAcsCount: 0,//今日刷卡数
			},
			//设备类别
			ControllerType: [],
			//设备总数
			ControllerCount: 0,
		}
	},
	mounted() {
		//查询公司
		this.QueryBs_Company();
	},
	methods: {
		// 查询公司
		async QueryBs_Company() {
			try {
				this.CompanyLoadStatus = true; //显示加载动画、
				var { data: res } = await this.$http.post('queryBsCompany', this.QueryCompany);
				this.CompanyLoadStatus = false; //关闭加载动画
				if (this.ReturnFiltration(res.code)) {
					this.tableData = res.result.Query;
					this.CompanyCount = res.result.Count; //条数
				}
			} catch (e) {
				this.$notify.error({
					title: '错误',
					message: "QueryCs_Company:" + e
				});
				this.CompanyLoadStatus = false;
			}
		},
		// 重置
		reset() {
			this.QueryCompany = { CompanyName: "", ContactName: '', ContactPhone: '', Count: 20, Pages: 1 }
		},
		//表格多选事件
		TableCheckbox(e) {
			var array = [];
			for (var i = 0; i < e.length; i++) {
				array[i] = e[i].companyid;
			}
			this.TableCheckboxArray = array;
		},
		//分页
		CurrentChange(index) {
			this.QueryCompany.Pages = index;
			this.QueryBs_Company();
		},
		//显示新增对话框
		AddDialog() {
			this.DialogTitle = "新增公司 ";
			this.DialogVisible = true;
			this.Form = {
				'companyid': 0,//公司ID
				'companyname': '',//公司名称
				'companycode': '',//公司代码
				'companylogo': '',//公司Logo
				'companynote': '',//公司简介
				'authorizationstarttime': '',//授权开始时间
				'authorizationendtime': '',//授权结束时间
				'name': '',//联络人名称
				'telephone': '',//联络人电话
				'email': '',//邮箱
				'personNum': 10000,//限制人员数
				'equipmentNum': 200,//限制设备数
				'username': '',//登录名
				'userpassword': '',//登录密码
				'authorizationaccount': '10',//授权账户数
				'secretkey': 'CBAB2018AP@#',//二维码密钥
				'eid': this.EID(),//物业代码
				'icseradd': '8',//读卡扇区
				'icserkey': 'FFFFFFFFFFF0',//扇区密钥
				'companyauthority': [
					{ 'authoritykey': 'Basic', 'authorityvalue': true, 'Time': [this.ThisTime(0), this.ThisTime(365)], 'authoritydescribe': '基本功能' },
					{ 'authoritykey': 'Visitor', 'authorityvalue': false, 'Time': [this.ThisTime(0), this.ThisTime(365)], 'authoritydescribe': '访客模块' },
					{ 'authoritykey': 'Lift', 'authorityvalue': false, 'Time': [this.ThisTime(0), this.ThisTime(365)], 'authoritydescribe': '梯控模块' },
				]
			}
		},
		//当前时间
		ThisTime(Day) {
			var Time = new Date();
			Time.setDate(Time.getDate() + Day);
			var year = Time.getFullYear();
			var month = Time.getMonth() + 1;
			var day = Time.getDate();
			if (month < 10) month = "0" + month;
			if (day < 10) day = "0" + day;
			var hours = Time.getHours();
			var mins = Time.getMinutes();
			var secs = Time.getSeconds();
			var msecs = Time.getMilliseconds();
			if (hours < 10) hours = "0" + hours;
			if (mins < 10) mins = "0" + mins;
			if (secs < 10) secs = "0" + secs;
			if (msecs < 10) secs = "0" + msecs;
			if (Day > 0) {
				return year + "-" + month + "-" + day + " 23:59:59";
			}
			else {
				return year + "-" + month + "-" + day + " 00:00:00";
			}
		},
		//时间转为小时/天数
		TimeTransformDay(Time) {
			if (Time == null) return 0 + "天";
			if (Time.length == 0) return 0 + "天";
			if (Time[0].length == 0 || Time[1].length == 0) return 0 + "天";
			var Time1 = new Date(Time[0]);
			var Time2 = new Date(Time[1]);
			var getTime = Time2.getTime() - Time1.getTime();
			var Value = parseInt(getTime / (1000 * 60 * 60 * 24));
			if (Value == 0) {
				Value = Math.ceil(getTime / (1000 * 60 * 60)) + "小时";
			}
			else {
				Value = Value + "天";
			}
			return Value;
		},
		// 基础功能开关
		BasicSwitch(e) {
			if (!e) {
				var companyauthority = this.Form.companyauthority;
				for (var i = 0; i < companyauthority.length; i++) {
					if (companyauthority[i].authoritykey != "Basic") {
						companyauthority[i].authorityvalue = false;
					}
				}
			}
		},
		// 功能模块开关
		ModuleSwitch(e) {
			if (e) {
				var companyauthority = this.Form.companyauthority;
				for (var i = 0; i < companyauthority.length; i++) {
					if (companyauthority[i].authoritykey == "Basic") {
						companyauthority[i].authorityvalue = true;
					}
				}
			}
		},
		//物业代码
		EID() {
			var myDate = new Date();
			var getFullYear = myDate.getFullYear();
			getFullYear = getFullYear.toString().substring(2, 4)
			var getMonth = myDate.getMonth() + 1;
			getMonth = getMonth >= 10 ? getMonth : '0' + getMonth;
			return getFullYear + getMonth;
		},
		//新增修改公司信息
		addUpdateBsCompany() {
			this.$refs.RuleForm.validate(async valid => {
				if (!valid) return;
				this.DialogButtonLoad = true;//显示按钮加载动画
				for (var i = 0; i < this.Form.companyauthority.length; i++) {
					var Time = this.Form.companyauthority[i].Time;
					if (Time == null) {
						this.Form.companyauthority[i].Time = ['2020-01-01 00:00:00', '2020-01-01 00:00:00'];
						break;
					}

					if (Time != null) {
						var starttime = this.Form.companyauthority[i].Time[0];
						var endtime = this.Form.companyauthority[i].Time[1];
						if (starttime == '' || endtime == '') {
							this.Form.companyauthority[i].Time[0] = '2020-01-01 00:00:00';
							this.Form.companyauthority[i].Time[1] = '2020-01-01 00:00:00';
							break;
						}
					}
				}
				var { data: res } = await this.$http.post('addUpdateBsCompany', this.Form);
				if (this.ReturnFiltration(res.code)) {
					if (res.result.Status == '1') {
						if (this.Form.companyid == 0) {
							this.Message('添加成功', "success");
							this.QueryBs_Company();
						}
						else {
							this.Message('修改成功', "success");
							this.QueryBs_Company();
						}
						this.DialogVisible = false;
					}
					else if (res.result.Status == '2') {
						this.Message('公司名称已存在', "warning");
					}
					else if (res.result.Status == '3') {
						this.Message('登录名已存在', "warning");
					}
					else {
						this.Message('操作失败', "error");
					}
				}
				this.DialogButtonLoad = false;//隐藏按钮加载动画
			})
		},

		//显示修改对话框
		UpdateDialog(e) {
			this.DialogTitle = "修改公司";
			this.DialogVisible = true;
			this.Form = {
				'companyid': e.companyid,//公司ID
				'companyname': e.companyname,//公司名称
				'companycode': e.companycode,//公司代码
				'companylogo': e.companylogo != "" ? this.$BsImageUrl + e.companylogo : "",//公司Logo
				'companynote': e.companynote,//公司简介
				'authorizationstarttime': e.authorizationstarttime,//授权开始时间
				'authorizationendtime': e.authorizationendtime,//授权结束时间
				'name': e.contact,//联络人名称
				'telephone': e.telephone,//联络人电话
				'email': e.email,//邮箱
				'personNum': e.personNum,//限制人员数
				'equipmentNum': e.equipmentNum,//限制设备数
				'username': e.username,//登录名
				'userpassword': e.userpassword,//登录密码
				'authorizationaccount': e.authorizationaccount,//授权账户数
				'secretkey': e.secretkey,//二维码密钥
				'eid': e.eid,//物业代码
				'icseradd': e.icseradd,//读卡扇区
				'icserkey': e.icserkey,//扇区密钥
				'companyauthority': [
					{ 'authoritykey': 'Basic', 'authorityvalue': true, 'Time': [], 'authoritydescribe': '基本功能' },
					{ 'authoritykey': 'Visitor', 'authorityvalue': false, 'Time': [], 'authoritydescribe': '访客模块' },
					{ 'authoritykey': 'Lift', 'authorityvalue': false, 'Time': [], 'authoritydescribe': '梯控模块' },
				]
			}
			this.QueryBs_CompanyAuthority();
		},
		//查询权限
		async QueryBs_CompanyAuthority() {
			try {
				var CompanyId = this.Form.companyid; //公司Id
				var { data: res } = await this.$http.post('queryBsCompanyAuthority', { "CompanyId": CompanyId });
				if (this.ReturnFiltration(res.code)) {
					var companyAuthority = res.result.Query;
					var Status = this.Form.authorizationstarttime == '2020-01-01 00:00:00' && this.Form.authorizationendtime == '2020-01-01 00:00:00' ? false : true;
					var array = [];
					array[0] = {
						'authoritykey': 'Basic',
						'authorityvalue': Status,
						'Time': [this.Form.authorizationstarttime, this.Form.authorizationendtime],
						'authoritydescribe': '基本功能'
					};
					for (var i = 0; i < companyAuthority.length; i++) {
						array[array.length] = {
							'authoritykey': companyAuthority[i].funname,
							'authorityvalue': companyAuthority[i].funvalue == 1 ? true : false,
							'Time': [companyAuthority[i].starttime != undefined ? companyAuthority[i].starttime : '', companyAuthority[i].endtime != undefined ? companyAuthority[i].endtime : ''],
							'authoritydescribe': companyAuthority[i].funnote
						}
					}
					this.Form.companyauthority = array;
					this.companyAuthority = array;
				}
			} catch (e) {
				this.$notify.error({
					title: '错误',
					message: "QueryCs_Company:" + e
				});
				this.CompanyLoadStatus = false;
			}
		},
		//删除公司
		async deleteBsCompany() {
			if (this.TableCheckboxArray.length > 0) {
				this.$prompt('请输入"确认删除"', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^确认删除$/,
					inputErrorMessage: '输入不正确'
				}).then(async ({ value }) => {
					var { data: res } = await this.$http.post('deleteBsCompany', this.TableCheckboxArray);
					if (this.ReturnFiltration(res.code)) {
						if (res.result.Status == '1') {
							this.Message('删除成功', "success");
							this.QueryBs_Company();
						}
						else {
							this.Message('删除失败', "error");
						}
					}
				}).catch(() => {
					//取消输入      
				});
			}
			else {
				this.Message('请选择要删除的公司', "warning");
			}
		},
		//删除公司
		deleteBsCompany2(companyid) {
			this.$prompt('请输入"确认删除"', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^确认删除$/,
				inputErrorMessage: '输入不正确'
			}).then(async ({ value }) => {
				var { data: res } = await this.$http.post('deleteBsCompany', [companyid]);
				if (this.ReturnFiltration(res.code)) {
					if (res.result.Status == '1') {
						this.Message('删除成功', "success");
						this.QueryBs_Company();
					}
					else {
						this.Message('删除失败', "error");
					}
				}
			}).catch(() => {
				//取消输入      
			});
		},
		//详情
		Details(e) {
			this.companyId = e.companyid;
			this.Form = {
				'companyid': e.companyid,//公司ID
				'companyname': e.companyname,//公司名称
				'companycode': e.companycode,//公司代码
				'companylogo': e.companylogo != "" ? this.$BsImageUrl + e.companylogo : "",//公司Logo
				'companynote': e.companynote,//公司简介
				'authorizationstarttime': e.authorizationstarttime,//授权开始时间
				'authorizationendtime': e.authorizationendtime,//授权结束时间
				'name': e.contact,//联络人名称
				'telephone': e.telephone,//联络人电话
				'email': e.email,//邮箱
				'username': e.username,//登录名
				'userpassword': e.userpassword,//登录密码
				'authorizationaccount': e.authorizationaccount,//授权账户数
				'personNum': e.personNum,//限制人员数
				'equipmentNum': e.equipmentNum,//限制设备数
				'secretkey': e.secretkey,//二维码密钥
				'eid': e.eid,//物业代码
				'icseradd': e.icseradd,//读卡扇区
				'icserkey': e.icserkey,//扇区密钥
				'companyauthority': [
					{ 'authoritykey': 'Basic', 'authorityvalue': true, 'Time': [], 'authoritydescribe': '基本功能' },
					{ 'authoritykey': 'Visitor', 'authorityvalue': false, 'Time': [], 'authoritydescribe': '访客模块' },
					{ 'authoritykey': 'Lift', 'authorityvalue': false, 'Time': [], 'authoritydescribe': '梯控模块' },
				]
			}
			this.QueryBs_CompanyAuthority();//查询权限
			this.queryBsCount();//查询人员设备刷卡数
			this.cardRecord();//查询今日验证占比
			this.controllerTypeCount();//查询设备占比
		},
		//权限有效占比
		EffectiveProportion(item) {
			if (item.Time == null) return 0;
			if (item.Time.length < 2) return 0;
			if (item.authorityvalue == 1) {
				if (item.Time[0] != "" && item.Time[1] != "") {
					var SysTime = new Date();
					var starttime = new Date(item.Time[0].replace(/-/g, "/"));
					var endtime = new Date(item.Time[1].replace(/-/g, "/"));
					if (SysTime >= starttime && SysTime <= endtime) {
						var end = SysTime.getTime() - starttime.getTime(); //时间差的毫秒数
						end = parseInt(end / 1000 / 60); //相差分钟数
						var sum = endtime.getTime() - starttime.getTime(); //时间差的毫秒数
						sum = parseInt(sum / 1000 / 60); //相差分钟数
						var proportion = parseInt(end / sum * 100);
						return proportion;
					} else {
						if (SysTime >= endtime) {
							return 100;
						} else {
							return 0;
						}
					}
				} else {
					return 0;
				}
			} else {
				return 0;
			}
		},
		//查询人员设备刷卡数
		async queryBsCount() {
			try {
				var { data: res } = await this.$http.post('queryBsCount', { "CompanyId": this.companyId });
				if (this.ReturnFiltration(res.code)) {
					this.companyDetails.EmployeesCount = res.result.EmployeesCount;//人员数
					this.companyDetails.ControllersCount = res.result.ControllersCount; //设备数
					this.companyDetails.BrushcardAcsCount = res.result.BrushcardAcsCount; //今日刷卡数
				}
			} catch (e) {
				this.$notify.error({
					title: '错误',
					message: "QueryCs_Company:" + e
				});
				this.CompanyLoadStatus = false;
			}
		},
		//刷卡流量统计
		async cardRecord() {
			try {
				var { data: res } = await this.$http.post('cardRecord', { "CompanyId": this.companyId });
				if (this.ReturnFiltration(res.code)) {
					var Record = res.result.cardRecord;
					let myCard = this.$echarts.init(document.getElementById('myCard'), 'light');
					var option = {
						color: ['#409EFF', '#67C23A', '#E6A23C', '#E94B73'],
						tooltip: {
							trigger: 'axis'
						},
						grid: {
							left: '3%',
							top: '5%',
							right: '3%',
							bottom: '10%',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: this.TimeQuantum()
						},
						yAxis: {
							type: 'value',
							splitNumber: 3,
							splitLine: {
								show: true,
								lineStyle: {
									type: 'dashed'
								}
							}
						},
						series: [
							{
								name: '二维码',
								smooth: true,
								data: this.CardCount(Record, "1"),
								type: 'line',
								areaStyle: {},
								symbolSize: 10,
								symbol: 'circle',
								lineStyle: {
									color: "#409EFF",
									width: 3,
								},
								areaStyle: {
									color: "rgba(246,248,253, 0.5)"
								}
							},
							{
								name: 'IC卡',
								smooth: true,
								data: this.CardCount(Record, "2"),
								type: 'line',
								areaStyle: {},
								symbolSize: 10,
								symbol: 'circle',
								lineStyle: {
									color: "#67C23A",
									width: 3,
								},
								areaStyle: {
									color: "rgba(246,248,253, 0.5)"
								}
							},
							{
								name: '密码卡号',
								smooth: true,
								data: this.CardCount(Record, "3"),
								type: 'line',
								areaStyle: {},
								symbolSize: 10,
								symbol: 'circle',
								lineStyle: {
									color: "#E6A23C",
									width: 3,
								},
								areaStyle: {
									color: "rgba(246,248,253, 0.5)"
								}
							},
							{
								name: '人脸',
								smooth: true,
								data: this.CardCount(Record, "4"),
								type: 'line',
								areaStyle: {},
								symbolSize: 10,
								symbol: 'circle',
								lineStyle: {
									color: "#E94B73",
									width: 3,
								},
								areaStyle: {
									color: "rgba(246,248,253, 0.5)"
								}
							},
						]
					};
					myCard.setOption(option);
					window.onresize = function () {
						myCard.resize();
					}
				}
			} catch (e) {
				this.$notify.error({ title: '错误', message: "QueryCs_Company:" + e });
			}
		},
		//刷卡次数
		CardCount(Record, Type) {
			var Data = {};
			for (var i = 0; i < Record.length; i++) {
				if (Record[i].cardType == Type) {
					Data = Record[i];
					break;
				}
			}
			var ReturnData = [];
			var HH = new Date().getHours()
			for (var i = 0; i < 24; i++) {
				if (i <= HH) {
					if (Data["time" + i] != undefined) {
						ReturnData[ReturnData.length] = Data["time" + i];
					}
					else {
						ReturnData[ReturnData.length] = 0;
					}
				}
			}
			return ReturnData;
		},
		//时间段
		TimeQuantum() {
			var Time = [];
			for (var i = 0; i < 24; i++) {
				var StartHH = i < 10 ? '0' + i + ":00" : i.toString() + ":00";
				var endHH = "";
				if (i == 23) {
					endHH = "23:00";
				}
				else {
					endHH = (i + 1) < 10 ? '0' + (i + 1) + ":00" : (i + 1).toString() + ":00";
				}
				Time[i] = StartHH + " - " + endHH;
			}
			return Time;
		},
		//百分比
		Percentage(num, total) {
			if (num == 0 || total == 0) {
				return 0;
			}
			var ratio = (num / total * 100);
			if (ratio.toString().indexOf(".") != -1) {
				let index = ratio.toString().indexOf('.');
				ratio = Number(ratio.toString().slice(0, index + 3));
			}
			return ratio;
		},
		//查询设备占比
		async controllerTypeCount() {
			try {
				var { data: res } = await this.$http.post('controllerTypeCount', { "CompanyId": this.companyId });
				if (this.ReturnFiltration(res.code)) {
					var Data = res.result.controllerTypeCount;
					var count = 0;
					var controllersubtype = [
						{ "controllersubtype": "CR02E", "count": "0" },
						{ "controllersubtype": "CR02EU", "count": "0" },
						{ "controllersubtype": "CR03E", "count": "0" },
						{ "controllersubtype": "CR07EU", "count": "0" },
						{ "controllersubtype": "F6", "count": "0" }
					]
					for (var i = 0; i < controllersubtype.length; i++) {
						for (var j = 0; j < Data.length; j++) {
							if (controllersubtype[i].controllersubtype == Data[j].controllersubtype) {
								controllersubtype[i].count = Data[j].count
							}
						}
					}
					this.ControllerType = controllersubtype;
					for (var i = 0; i < Data.length; i++) {
						count += Data[i].count;
					}
					this.ControllerCount = count;
				}
			} catch (e) {
				this.$notify.error({
					title: '错误',
					message: "QueryCs_Company:" + e
				});
				this.CompanyLoadStatus = false;
			}
		},
		// 返回过滤
		ReturnFiltration(code) {
			if (code == "100001") {
				//成功
				return true;
			} else if (code == "100002") {
				//Session过期
				this.Message('身份已过期，请重新登录', "warning");
				return false;
			} else if (code == "100003") {
				//校验失败
				this.Message('校验失败，请重新登录', "error");
				return false;
			} else if (code == "100004") {
				//格式不正确
				this.Message('请求参数不正确', "error");
				return false;
			} else if (code == "100005") {
				//操作失败
				this.Message('操作失败', "error");
				return false;
			}
		},
		//提醒消息
		Message(message, type) {
			this.$message({ showClose: true, message: message, type: type, offset: "14" });
		}
	}
}
</script>

<style scoped>
.BsCompany {
	width: 100%;
	height: 100%;
	border-radius: 10px;

}

.BsCompany>div {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

/* 卡标题 */
.cardTitle {
	color: #303133;
	font-size: 13px;
	margin: 20px;
	margin-bottom: 0px;
}

/* 头部 */
.top {
	height: 75px;
	padding: 0px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.top>div {
	display: flex;
}

.top-left {
	display: flex;
	align-items: center;
	flex-grow: 1;
	width: 0px;
}

.top-left>div {
	display: flex;
	align-items: center;
	flex-grow: 1;
	width: 0px;
}

.search_item {
	margin-right: 15px;
	font-size: 13px;
	color: #606266;
}

.search_value {
	flex-grow: 1;
	width: 0px;
	margin-right: 20px;
}

/* 搜索 */
.search_value>>>.el-input__inner {
	background-color: #F7F8FA;
	border: none;
}

/* 分割线 */
.divider {
	margin: 5px 20px;
	border-bottom: 1px solid #EBEEF5;
}

/* 内容表格 */
.centre {
	flex: 1;
	height: 0px;
	display: flex;
	flex-direction: column;
}

.operation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 20px;
	margin-bottom: 15px;
}

.Table {
	height: 100%;
	display: flex;
	margin: 10px 20px;
	margin-top: 0px;
}

.Table-Attribute {
	overflow-x: auto;
}

.bottom {
	display: flex;
	align-items: center;
	height: 60px;
	font-size: 15px;
	color: #909399;
	padding-left: 20px;
}

/* 详情页 */
.CompanyLogo {
	margin-top: 10px;
	margin-bottom: 20px;
	width: 150px;
	height: 150px;
	border-radius: 10px;
	border: 1px solid #E6E6E6;
}

.CompanyLogo img {
	display: inline-block;
	width: 90%;
	max-width: 100%;
	height: auto;
}

.centre-value {
	width: 100%;
	display: flex;
	flex-grow: 1;
	margin: 0px auto;
}

.centre-left {
	width: 30%;
	height: 100%;
	border-radius: 10px;
	background-color: #FFFFFF;
	overflow-x: hidden;
	overflow-y: auto;
}

.centre-right {
	height: 100%;
	display: flex;
	flex-grow: 1;
	margin-left: 20px;
}

.centre-right>div {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.CompanyNote {
	font-size: 15px;
	color: #909399;
}

.Company-value {
	margin-top: 20px;
	font-size: 15px;
	color: #606266;
}

.Company-Index {
	display: flex;
	margin-bottom: 10px;
}

.Company-Index-Title {
	width: 130px;
	display: flex;
	align-items: center;
}

.Company-Index-Value {
	width: 77%;
	height: 100%;
}

.Company-Index-Item {
	width: 100%;
	display: flex;
}

.box-card {
	height: 100%;
}

.centre-right-top {
	width: 100%;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.centre-right-top>div {
	width: 32%;
	height: 100%;
	display: flex;
	align-items: center;
	border-radius: 10px;
	justify-content: flex-end;
}

.centre-right-top>div>div {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Top-Content {
	width: 90%;
	height: 90%;
	display: flex;
	justify-content: space-between;
}

.Top-Index-Left {
	height: 100%;
}

.Top-Left-Name {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #636363;
	height: 30%;
}

.Top-Left-Value {
	display: flex;
	align-items: center;
	font-size: 40px;
	font-weight: 700;
	height: 40%;
}

.Top-Left-describe {
	display: flex;
	align-items: center;
	font-size: 13px;
	color: #959595;
	height: 30%;
}

.Top-Index-right {
	height: 100%;
}

.Top-Index-right>div {
	width: 65px;
	height: 65px;
	border-radius: 50%;
}

.Top-Index-right>div>i {
	color: #FFFFFF;
	font-size: 30px;
}

.Top-Index1 {
	background-color: #23CCB3;
	color: #23CCB3;
}

.Top-Index2 {
	background-color: #409EFF;
	color: #409EFF;
}

.Top-Index3 {
	background-color: #8354C8;
	color: #8354C8;
}

.Top-Index4 {
	background-color: #E94B73;
	color: #E94B73;
}

.equipment {
	width: 100%;
	margin: 0px auto;
	margin-top: 20px;
	padding-bottom: 10px;
	border-radius: 10px;
	background-color: #FFFFFF;
}

.equipment-Title {
	padding: 15px 20px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #606266;
}

.equipment-Value {
	height: 270px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 10px;
}

.equipment-Value>div {
	display: flex;
	margin-bottom: 25px;
}

.equipment-Type {
	padding-left: 25px;
	display: flex;
	align-items: center;
	font-size: 14px;
	width: 80px;
	color: #606266;
}

.equipment-Type-value {
	width: 88%;
}

.Card {
	width: 100%;
	flex-grow: 1;
	margin-top: 20px;
	border-radius: 10px;
	background-color: #FFFFFF;
	border: 1px solid #EBEEF5;
}

.Card-Title {
	width: 97%;
	margin: 0px auto;
	height: 14%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #606266;
	font-size: 14px;
}

.Card-hint {
	display: flex;
	align-items: center;
}

.Card-hint>div {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin: 0px 10px;
}


.Card-hint i {
	font-size: 20px;
}

.Graph1 {
	width: 100%;
	height: 86%;
}


/* 新增修改弹框 */
.Dialog-Form {
	height: 500px;
	overflow-y: auto;
}

.Dialog-Form-Left {
	width: 98%;
}

.Form-CompanyAuthority {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Form-CompanyAuthority-Switch {
	display: flex;
	align-items: center;
}

.Form-CompanyAuthority-Time {
	margin-left: 30px;
}
</style>

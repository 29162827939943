import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Index from '../components/Index.vue'
import BsStatistics from '../views/BsStatistics.vue'
import CsStatistics from '../views/CsStatistics.vue'
import Home from '../views/Home.vue'
import CsCompany from '../views/CsCompany.vue'
import BsCompany from '../views/BsCompany.vue'
import BsApply from '../views/BsApply.vue'
import Apply from '../views/Apply.vue'
import OperationLog from '../views/OperationLog.vue'

Vue.use(VueRouter)

const routes = [
	{path: '/',redirect:'/login'},
	{path: '/login',component: Login},
	{path: '/Index',component: Index,redirect:'/BsStatistics',children:[
		{path: '/BsStatistics',component: BsStatistics},
		{path: '/CsStatistics',component: CsStatistics},
		{path: '/BsCompany',component: BsCompany},
		{path: '/Home',component: Home},
		{path: '/CsCompany',component: CsCompany},
		{path: '/OperationLog',component: OperationLog},
		{path: '/BsApply',component: BsApply}
	]},
	{path: '/Apply',component: Apply},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/About.vue')
	}
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
	if(to.path === '/Login') return next()
	if(to.path === '/Apply') return next()
	//获取保存的信息
	const tokenStr = window.sessionStorage.getItem('UserStatus')
	if(!tokenStr) return next('/Login')
	next()
})

export default router

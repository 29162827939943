<template>
    <div class="item_card">
        <div class="query_card">
            <i class="el-icon-search"></i>
            <el-input v-model="searchCriteria.value" placeholder="请输入内容进行查询"></el-input>
            <el-button size="small" type="primary" @click="queryOperationRecord">查询</el-button>
        </div>
        <div class="table_card">
            <div class="card_title">操作记录</div>
            <div class="Table_content" v-loading="tableCardLoad">
                <el-timeline style="margin-left: -20px;">
                    <el-timeline-item v-for="(activity, index) in recordTable"  :key="index" :timestamp="activity.description">
                    {{activity.title}} {{activity.recorddatetime}} 
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="paging">
                共 {{recordTableSum}} 条 <el-pagination background layout="prev, pager, next" @current-change="CurrentChange" :page-size="20" :total="recordTableSum"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "OperationLog",
		data() {
			return {
                // 操作记录卡片加载动画
                tableCardLoad:false,
                // 搜索条件
				searchCriteria:{
                    value:"",
                    //条数
                    count: 20,
                    //页数
					pages: 1,
                },
                // 记录列表
                recordTable:[],
                // 记录列表总数
                recordTableSum:0,
			}
		},
		mounted() {
            // 查询操作记录
            this.queryOperationRecord();
		},
		methods: {
			// 查询操作记录
			async queryOperationRecord() {
				try {
                    // 显示加载动画、
					this.tableCardLoad = true;
					var {data: res} = await this.$http.post('queryOperationRecord', this.searchCriteria);
                    // 关闭加载动画
					this.tableCardLoad = false;
                    // 过滤结果
					if (this.returnFiltration(res.code)) {
                        // 记录列表总数
						this.recordTableSum = res.result.sum;
                        // 记录列表内容
						this.recordTable = res.result.recordTable;
					}
				} catch (e) {
					this.$notify.error({ title: '错误', message: "QueryCs_Company:" + e });
					this.tableCardLoad = false;
				}
			},
			//分页
			CurrentChange(index){
				this.searchCriteria.pages = index;
				this.queryOperationRecord();
			},
            // 过滤结果
			returnFiltration(code) {
				if (code == "100001") {
					//成功
					return true;
				} else if (code == "100002") {
					//Session过期
					this.Message('身份已过期，请重新登录', "warning");
					return false;
				} else if (code == "100003") {
					//校验失败
					this.Message('校验失败，请重新登录', "error");
					return false;
				} else if (code == "100004") {
					//格式不正确
					this.Message('请求参数不正确', "error");
					return false;
				} else if (code == "100005") {
					//操作失败
					this.Message('操作失败', "error");
					return false;
				}
			},
		}
	}
</script>

<style scoped>
    .item_card{
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: column;
    }
    /* 卡片标题 */
    .card_title{
        font-size: 13px;
        color: #303133;
        margin-bottom: 20px;
    }

    /* 查询卡片 */
    .query_card{
        height: 70px;
        margin-bottom: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        background-color: #FFFFFF;
    }

    /* 搜索图标 */
    .el-icon-search{
        font-size: 25px;
        color: #909399;
    }

    .query_card >>> .el-input__inner{
		background-color: #FFFFFF;
        font-size: 15px;
		border: none;
	}

    /* 表格卡片 */
    .table_card{
        height: 0px;
        flex-grow: 1;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }
    /* 表格内容 */
    .Table_content{
        height: 0px;
        overflow: auto;
        flex-grow: 1;
    }
    /* 分页 */
    .paging{
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #606266;
        margin-top: 20px;
    }
</style>

<template>
	<div class="Company">
		<div class="Left" v-loading="CompanyLoadStatus">
			<!-- 头部功能栏 -->
			<div class="ControlStrip">
				<!-- 搜索栏 -->
				<div class="SearchBox">
					<i class="el-icon-search"></i>
					<el-input class="SearchInput" @input="QueryCs_Company" v-model="QueryCompany.Condition"
						placeholder="搜索内容"></el-input>
					<div class="split"></div>
					<el-select class="ELSelect" v-model="QueryCompany.ConditionType" @change="QueryCs_Company"
						placeholder="请选择">
						<el-option label="公司名称" value="1"></el-option>
						<el-option label="联系人" value="2"></el-option>
						<el-option label="手机号" value="3"></el-option>
					</el-select>
				</div>
				<!-- 日期框 -->
				<div class="DateBox flex-center">
					<el-date-picker class="DateTime" @change="QueryCs_Company" prefix-icon="el-icon-date"
						v-model="SearchDateTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
						:default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</div>

				<!-- 功能按钮 -->
				<div class="Button flex-center">
					<div class="ButtonIcon flex-center">
						<el-tooltip effect="dark" content="添加授权公司" placement="left">
							<i class="el-icon-circle-plus" style="color: #409EFF;" @click="AddDialog"></i>
						</el-tooltip>
					</div>
					<div class="split"></div>
					<div class="ButtonIcon flex-center">
						<el-popconfirm title="确定要删除选中的公司吗？" @confirm="deleteCsCompany">
							<el-tooltip slot="reference" effect="dark" content="删除授权公司" placement="top">
								  <i class="el-icon-delete-solid" style="color: #F56C6C;"></i>
							</el-tooltip>
						</el-popconfirm>
					</div>
					<div class="split"></div>
					<div class="ButtonIcon flex-center">
						<el-tooltip effect="dark" content="刷新" placement="right">
							<i class="el-icon-refresh-right" style="color: #909399;" @click="QueryCs_Company"></i>
						</el-tooltip>
					</div>
				</div>
			</div>
			<div class="Table flex-center">
				<div class="Table-Content">
					<div class="Taoble-Title">公司信息</div>
					<!-- <div class="Taoble-Remark">选择指定的行可以显示该公司授权功能及信息</div> -->
					<el-table :data="tableData" class="Table-Attribute" highlight-current-row @selection-change="TableCheckbox" @current-change="PitchOn" height="100%">
						<el-table-column type="selection" align="center"></el-table-column>
						<el-table-column prop="companyname" align="center" label="公司名称"></el-table-column>
						<el-table-column prop="name" align="center" width="110" label="联系人"></el-table-column>
						<el-table-column prop="telephone" align="center" label="手机号"></el-table-column>
						<el-table-column prop="Status" align="center" width="110" label="状态">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status==0" type="warning">未激活</el-tag>
								<el-tag v-else-if="scope.row.status==1 && scope.row.authorityValue==1" type="success">正常
								</el-tag>
								<el-tag v-else-if="scope.row.status==1 && scope.row.authorityValue==2" type="danger">已过期
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="adddattime" align="center" label="添加日期"></el-table-column>
						<el-table-column align="center" fixed="right" width="80" label="操作">
							<template slot-scope="scope">
								<el-button type="primary" size="mini" plain icon="el-icon-edit" @click="UpdateDialog(scope.row)"></el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="paging">
						共 {{CompanyCount}} 条 <el-pagination background layout="prev, pager, next" @current-change="CurrentChange" :page-size="20" :total="CompanyCount">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<div class="Right">
			<div class="Right-Top">
				<div :class="Index=='1'?'Right-Top-button2 flex-center':'Right-Top-button1 flex-center'"
					@click="buttonIndex(1)">
					<i class="el-icon-office-building Right-Top-Button"></i>
					公司信息
				</div>
				<div :class="Index=='2'?'Right-Top-button2 flex-center':'Right-Top-button1 flex-center'"
					@click="buttonIndex(2)">
					<i class="el-icon-unlock Right-Top-Button"></i>
					授权功能
				</div>
				<div :class="Index=='3'?'Right-Top-button2 flex-center':'Right-Top-button1 flex-center'"
					@click="buttonIndex(3)">
					<i class="el-icon-time Right-Top-Button"></i>
					更新历史
				</div>
			</div>
			<div class="Right-Content flex-center">
				<div class="IndexContent flex-center">
					<el-empty v-if="TableIndexCompany.CompanyId==0" description="未选择公司"></el-empty>
					<div v-if="TableIndexCompany.CompanyId!=0 && Index==1" class="Index1">
						<div class="Logo flex-center">
							<img v-if="CompanyLogo!=''" :src="CompanyLogo" />
							<img v-else src="../assets/images/DefaultLogo.jpg" />
						</div>
						<div class="CompanyName">
							{{TableIndexCompany.Index1.companyname}}
						</div>
						<div class="CompanyDetails">
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">联系人</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.name}}</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">手机号</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.telephone}}</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">Email</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.email}}</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">限制人员数</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.personNum}} 个</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">限制设备数</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.equipmentNum}} 台</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">添加日期</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.adddattime}}</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">授权码</div>
								<div class="Item-Value flex-items-center">
									<span id="KeyValue"><el-link type="primary" @click="CopyKey">{{TableIndexCompany.Index1.key}}</el-link></span>&nbsp;&nbsp;
								</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">公司代码</div>
								<div class="Item-Value">{{TableIndexCompany.Index1.companycode}}</div>
							</div>
							<div class="CompanyDetails-Item">
								<div class="ItemTitle">授权状态</div>
								<div class="Item-Value">
									<span v-if="TableIndexCompany.Index1.status==1" class="CompanyStatus"
										style="color: #67C23A;">正常</span>
									<span v-if="TableIndexCompany.Index1.status==0" class="CompanyStatus"
										style="color: #E6A23C;">未激活</span>
								</div>
							</div>
							<div v-for="(item,index) in companyAuthority" :key="item.authorityid" :class="item.authorityvalue==1?'CompanyDetails-Item':''">
								<div v-if="item.authorityvalue==1" class="companyauthority-Item">
									<div class="ItemTitle">{{item.authoritydescribe}}</div>
									<div class="Item-Value">
										<el-progress :percentage="EffectiveProportion(item)" :color="customColors"></el-progress>
									</div>
								</div>
							</div>
							<div v-if="TableIndexCompany.Index1.status==1">
								<div class="CompanyDetails-Item">
									<div class="ItemTitle">激活信息</div>
									<div class="Item-Value flex-items-center">
										<el-link type="primary" @click="ReActivation">重置</el-link>
										<div class="Item-Value-Details">(重置后需要重新激活)</div>
									</div>
								</div>
								<div class="CompanyDetails-Item">
									<div class="ItemTitle">OS</div>
									<div class="Item-Value">{{TableIndexCompany.Index1.os}}</div>
								</div>
								<!-- <div class="CompanyDetails-Item">
									<div class="ItemTitle">OSID</div>
									<div class="Item-Value">{{TableIndexCompany.Index1.osid}}</div>
								</div> -->
								<div class="CompanyDetails-Item">
									<div class="ItemTitle">CPUID</div>
									<div class="Item-Value">{{TableIndexCompany.Index1.cpucode}}</div>
								</div>
								<div class="CompanyDetails-Item">
									<div class="ItemTitle">MAC</div>
									<div class="Item-Value">{{TableIndexCompany.Index1.mac}}</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="TableIndexCompany.CompanyId!=0 && Index==2" class="Index2">
						<el-card v-for="(item,index) in companyAuthority" class="box-card companyAuthority"
							shadow="hover">
							<div slot="header" class="clearfix">
								<span><i :class="companyAuthorityIcon[index]"></i> {{item.authoritydescribe}}</span>
								<div style="float: right;">
									<el-switch v-model="item.authorityvalue == 0 ? false : true" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
								</div>
							</div>
							<div>
								<div class="companyAuthority-Time">
									有效期&nbsp;&nbsp;{{item.starttime}}&nbsp;&nbsp;至&nbsp;&nbsp;{{item.endtime}}</div>
								<el-progress :percentage="EffectiveProportion(item)" :color="customColors">
								</el-progress>
							</div>
						</el-card>
					</div>
					<div v-if="TableIndexCompany.CompanyId!=0 && Index==3" class="Index3 flex-center">
						<el-empty v-if="AuthorityUpdate.length==0"></el-empty>
						<el-timeline v-else class="UpdateHistory">
							<el-timeline-item v-for="(item,index) in AuthorityUpdate" :key="item.recordid"
								:timestamp="item.updatedatetime" placement="top">
								<el-card shadow="hover">
									<div class="UpdateHistory-Index">ISCS版本&nbsp;&nbsp;&nbsp;{{item.version}}</div>
									<div class="UpdateHistory-Index">人员数量&nbsp;&nbsp;&nbsp;{{item.employeescount}}</div>
									<div class="UpdateHistory-Index">在线设备&nbsp;&nbsp;&nbsp;{{item.controllercount}}</div>
									<div class="UpdateHistory-Index">操作系统&nbsp;&nbsp;&nbsp;{{item.os}}</div>
									<!-- <div class="UpdateHistory-Index">系统编码&nbsp;&nbsp;&nbsp;{{item.osid}}</div> -->
									<div class="UpdateHistory-Index">CPU编码&nbsp;&nbsp;&nbsp;{{item.cpuid}}</div>
									<div class="UpdateHistory-Index">MAC地址&nbsp;&nbsp;&nbsp;{{item.mac}}</div>
								</el-card>
							</el-timeline-item>
						</el-timeline>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :title="DialogTitle" :visible.sync="DialogVisible" :close-on-click-modal="false" width="1150px">
			<div class="Dialog-Form">
				<div class="Dialog-Form-Left">
					<el-form ref="RuleForm" :rules="Rules" :model="Form" label-width="100px">
						<el-form-item label="公司名称" prop="companyname">
							<el-input maxlength="32" suffix-icon="el-icon-school" show-word-limit v-model="Form.companyname"></el-input>
						</el-form-item>
						<el-form-item label="公司代码" prop="companycode">
							<el-input maxlength="64" suffix-icon="el-icon-notebook-2" show-word-limit v-model="Form.companycode"></el-input>
						</el-form-item>
						<el-form-item label="联系人" prop="name">
							<el-input maxlength="32" suffix-icon="el-icon-user" show-word-limit v-model="Form.name"></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="telephone">
							<el-input maxlength="11" suffix-icon="el-icon-phone-outline" show-word-limit v-model="Form.telephone"></el-input>
						</el-form-item>
						<el-form-item label="Email" prop="email">
							<el-input maxlength="32" suffix-icon="el-icon-message" show-word-limit v-model="Form.email"></el-input>
						</el-form-item>
						<el-form-item label="限制人员数" prop="personNum">
							<el-input maxlength="11" suffix-icon="el-icon-user-solid" show-word-limit v-model="Form.personNum" placeholder="请输入限制人员数"></el-input>
						</el-form-item>
						<el-form-item label="限制设备数" prop="equipmentNum">
							<el-input maxlength="11" suffix-icon="el-icon-receiving" show-word-limit v-model="Form.equipmentNum" placeholder="请输入限制设备数"></el-input>
						</el-form-item>
						<el-form-item v-for="(item,index) in Form.companyauthority" :key="item.authoritykey" :label="item.authoritydescribe">
							<div class="Form-CompanyAuthority">
								<div class="Form-CompanyAuthority-Switch">
									<el-switch v-if="item.authoritykey=='Basic'" v-model="item.authorityvalue" @change="BasicSwitch" active-color="#13ce66" inactive-text="关闭" active-text="开启"></el-switch>
									<el-switch v-if="item.authoritykey!='Basic'" v-model="item.authorityvalue" @change="ModuleSwitch" active-color="#13ce66" inactive-text="关闭" active-text="开启"></el-switch>
								</div>
								<div class="Form-CompanyAuthority-Time">
									<el-tag type="info">有效期 {{TimeTransformDay(item.Time)}}</el-tag>&nbsp;&nbsp;&nbsp;
									<el-date-picker :disabled="!item.authorityvalue" type="datetimerange" :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
									 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" 
									 v-model="Form.companyauthority[index].Time"></el-date-picker>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="Dialog-Form-Right">
					<div class="Company-Logo flex-center">
						<el-upload
						  name = "Logo"
						  class="avatar-uploader"
						  :action="UpLoadUrl"
						  :show-file-list="false"
						  :with-credentials="true"
						  :before-upload="beforeAvatarUpload"
						  :on-error="onError"
						  :on-success="onSuccess">
						  <img v-if="CompanyLogo!=''" :src="CompanyLogo" class="avatar">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<div class="Company-Logo-button" v-if="CompanyLogo!=''">
						<el-button size="mini" @click="CompanyLogo='';Form.logo=''">删除LOGO</el-button>
					</div>
					<div class="Company-Logo-Title" v-if="CompanyLogo==''">公司Logo</div>
					<div class="Form-notice">
						注意事项
						<div>1.带 <span style="color: #F56C6C;">*</span> 为必填项</div>
						<div>2.公司Logo小不超过5M，PNG格式</div>
						<div>3.所有功能模块都基于基础功能,基础功能关闭,其他模块也随之关闭</div>
						<div>4.功能模块授权有效期默认一年</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogVisible = false">取 消</el-button>
				<el-button :loading="DialogButtonLoad" type="primary" @click="addUpdateCsCompany()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "Company",
		data() {
			//校验手机号
			var checkTelephone = async (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				}
				
				//校验格式
				var myreg = /^[0-9]*$/;
				if (!myreg.test(value)) {
				    return callback(new Error('手机号格式不正确'));
				}
			};
			//校验邮箱格式
			var checkEmail = async (rule, value, callback) => {
				//校验邮箱格式
				if(value!="")
				{
					var myreg =  /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
					if (!myreg.test(value)) {
						return callback(new Error('邮箱格式不正确'));
					}
				}
			};
			var checkPersonNum = async (rule, value, callback) => {
				if(!value){
					return callback(new Error('限制人员数不能为空'));
				}
				//校验格式
				var myreg = /^[0-9]*$/;
				if (!myreg.test(value)) {
				    return callback(new Error('格式不正确'));
				}
			};
			var checkEquipmentNum = async (rule, value, callback) => {
				if(!value){
					return callback(new Error('限制设备数不能为空'));
				}
				//校验格式
				var myreg = /^[0-9]*$/;
				if (!myreg.test(value)) {
				    return callback(new Error('格式不正确'));
				}
			};
			return {
				// 公司列表加载状态
				CompanyLoadStatus: false,
				QueryCompany: {
					Condition: "", //条件值
					ConditionType: '1', //条件类型
					StartTime: "", //开始时间
					EndTime: "", //结束时间
					Count: 20, //条数
					Pages: 1, //页数
				},
				// 添加日期
				SearchDateTime: [
					"2020-01-01 00:00:00",
					new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() +" 23:59:59"
				], 
				//条数
				CompanyCount: 0,
				// 公司信息
				tableData: [],
				// 右侧状态索引
				Index: "1",
				// 选中的公司信息
				TableIndexCompany: {
					CompanyId: 0, //公司Id
					Index1: {} //公司信息
				},
				//公司权限
				companyAuthority: [],
				//权限图标
				companyAuthorityIcon: [
					"el-icon-s-promotion",
					"el-icon-s-check",
					"el-icon-c-scale-to-original"
				],
				//授权功能进度条
				customColors: [
					{color: '#5cb87a',percentage: 20},
					{color: '#1989fa',percentage: 40},
					{color: '#6f7ad3',percentage: 60},
					{color: '#e6a23c',percentage: 80},
					{color: '#F56C6C',percentage: 100}
				],
				//更新记录
				AuthorityUpdate: [],
				//新增修改对话框标题
				DialogTitle: "",
				//公司logo
				CompanyLogo:"",
				//新增修改对话框显示状态
				DialogVisible: false,
				//新增修改按钮加载状态
				DialogButtonLoad:false,
				//表单信息
				Form: {
					companyid:0,//公司ID
					companyname: "",//公司名称
					companycode: "",//公司代码
					name: "",//联络人名称
					telephone: "",//联络人电话
					email: "",//邮箱
					personNum:0,//限制人员数
					equipmentNum:0,//限制设备数
					logo: "",//公司logo
					companyauthority:[] //权限
				},
				//表单校验
				Rules:{
					companyname:[{required:true,message:'请输入公司名称',trigger: 'blur'}],
					name:[{required:true,message:'请输入联系人名称',trigger: 'blur'}],
					telephone:[{required:true,validator: checkTelephone,trigger: 'blur'}],
					email:[{validator: checkEmail,trigger: 'blur'}],
					personNum:[{required:true,validator:checkPersonNum,trigger: 'blur'}],
					equipmentNum:[{required:true,validator:checkEquipmentNum,trigger: 'blur'}],
				},
				//图片上传地址
				UpLoadUrl:this.$ServerUrl+"upload",
				//勾选的公司
				TableCheckboxArray:[]
			}
		},
		mounted() {
			//查询本地安装的公司
			this.QueryCs_Company();
		},
		methods: {
			// 查询本地安装的公司
			async QueryCs_Company() {
				try {
					this.CompanyLoadStatus = true; //显示加载动画
					var SearchDateTime = this.SearchDateTime; //日期范围
					this.QueryCompany.StartTime = SearchDateTime.length > 0 ? SearchDateTime[0] : "";
					this.QueryCompany.EndTime = SearchDateTime.length > 0 ? SearchDateTime[1] : "";
					var {data: res} = await this.$http.post('queryCsCompany', this.QueryCompany);
					this.CompanyLoadStatus = false; //关闭加载动画
					if (this.ReturnFiltration(res.code)) {
						var Query = res.result.Query;
						this.CompanyCount = res.result.Count; //条数
						this.tableData = Query; //公司信息
						for(var i=0;i<Query.length;i++)
						{
							//更新选中的信息
							if(this.TableIndexCompany.CompanyId==Query[i].companyid)
							{
								this.PitchOn(Query[i]);
							}
						}
					}
				} catch (e) {
					this.$notify.error({
						title: '错误',
						message: "QueryCs_Company:" + e
					});
					this.CompanyLoadStatus = false;
				}
			},
			//分页
			CurrentChange(index){
				this.QueryCompany.Pages = index;
				this.QueryCs_Company();
			},
			//表格多选事件
			TableCheckbox(e){
				var array = [];
				for(var i=0;i<e.length;i++)
				{
					array[i] = e[i].companyid;
				}
				this.TableCheckboxArray = array;
			},
			// 表格选中事件
			PitchOn(obj) {
				if(obj!=null)
				{
					this.TableIndexCompany.CompanyId = obj.companyid; //公司Id
					this.TableIndexCompany.Index1 = obj; //选中的公司信息
					this.CompanyLogo = obj.logo!=""?this.$CsImageUrl+obj.logo:"";
					if (this.Index == 1 || this.Index == 2) {
						//更新授权功能
						this.QueryCs_CompanyAuthority();
					} else if (this.Index == 3) {
						//查询更新历史
						this.QueryCs_Authorityupdate();
					}
				}
			},
			//查询权限
			async QueryCs_CompanyAuthority() {
				try {
					var CompanyId = this.TableIndexCompany.CompanyId; //公司Id
					var {data: res} = await this.$http.post('queryCsCompanyAuthority', {"CompanyId": CompanyId});
					if (this.ReturnFiltration(res.code)) {
						var companyAuthority = res.result.Query;
						this.companyAuthority = companyAuthority;
						var array = [];
						for(var i=0;i<companyAuthority.length;i++)
						{
							array[i] = {
								'authoritykey':companyAuthority[i].authoritykey,
								'authorityvalue':companyAuthority[i].authorityvalue==1?true:false,
								'Time':[companyAuthority[i].starttime,companyAuthority[i].endtime],
								'authoritydescribe':companyAuthority[i].authoritydescribe
							}
						}
						this.Form.companyauthority = array;
					}
				} catch (e) {
					this.$notify.error({
						title: '错误',
						message: "QueryCs_Company:" + e
					});
					this.CompanyLoadStatus = false;
				}
			},
			//权限有效占比
			EffectiveProportion(item) {
				if (item.authorityvalue == 1) {
					if (item.starttime != "" && item.endtime != "") {
						var SysTime = new Date();
						var starttime = new Date(item.starttime.replace(/-/g, "/"));
						var endtime = new Date(item.endtime.replace(/-/g, "/"));
						if (SysTime >= starttime && SysTime <= endtime) {
							var end = SysTime.getTime() - starttime.getTime(); //时间差的毫秒数
							end = parseInt(end / 1000 / 60); //相差分钟数
							var sum = endtime.getTime() - starttime.getTime(); //时间差的毫秒数
							sum = parseInt(sum / 1000 / 60); //相差分钟数
							var proportion = parseInt(end / sum * 100);
							return proportion;
						} else {
							if (SysTime >= endtime) {
								return 100;
							} else {
								return 0;
							}
						}
					} else {
						return 0;
					}
				} else {
					return 0;
				}
			},
			// 按钮索引
			buttonIndex(Index) {
				this.Index = Index;
				if (Index == 1 || Index == 2) {
					//更新授权功能
					this.QueryCs_CompanyAuthority();
				} else if (Index == 3) {
					//查询更新历史
					this.QueryCs_Authorityupdate();
				}
			},
			//查询更新历史
			async QueryCs_Authorityupdate() {
				try {
					var CompanyId = this.TableIndexCompany.CompanyId; //公司Id
					var {data: res} = await this.$http.post('queryCsAuthorityupdate', {"CompanyId": CompanyId});
					if (this.ReturnFiltration(res.code)) {
						this.AuthorityUpdate = res.result.Query;
					}
				} catch (e) {
					this.$notify.error({
						title: '错误',
						message: "QueryCs_Company:" + e
					});
					this.CompanyLoadStatus = false;
				}
			},
			//显示新增对话框
			AddDialog() {
				this.DialogTitle = "新增公司 ";
				this.DialogVisible = true;
				this.Form = {
					'companyid':0,//公司ID
					'companyname': '',//公司名称
					'companycode': '',//公司代码
					'name': '',//联络人名称
					'telephone': '',//联络人电话
					'email': '',//邮箱
					'personNum': 10000,//限制人员数
					'equipmentNum': 200,//限制设备数
					'logo': '',//公司logo
					'companyauthority':[
						{'authoritykey':'Basic','authorityvalue':true,'Time':[this.ThisTime(0),this.ThisTime(365)],'authoritydescribe':'基本功能'},
						{'authoritykey':'Visitor','authorityvalue':false,'Time':[this.ThisTime(0),this.ThisTime(365)],'authoritydescribe':'访客模块'},
						{'authoritykey':'Lift','authorityvalue':false,'Time':[this.ThisTime(0),this.ThisTime(365)],'authoritydescribe':'梯控模块'},
					]
				}
			},
			//显示修改对话框
			UpdateDialog(e){
				this.DialogTitle = "修改公司";
				this.DialogVisible = true;
				this.CompanyLogo = e.logo!=""?this.$CsImageUrl+e.logo:"";
				this.Form = {
					'companyid':e.companyid,//公司ID
					'companyname': e.companyname,//公司名称
					'companycode': e.companycode,//公司代码
					'name': e.name,//联络人名称
					'telephone': e.telephone,//联络人电话
					'email': e.email,//邮箱
					'personNum':e.personNum,//限制人员数
					'equipmentNum':e.equipmentNum,//限制设备数
					'logo': e.logo,//公司logo
					'companyauthority':[
						{'authoritykey':'Basic','authorityvalue':true,'Time':[],'authoritydescribe':'基本功能'},
						{'authoritykey':'Visitor','authorityvalue':false,'Time':[],'authoritydescribe':'访客模块'},
						{'authoritykey':'Lift','authorityvalue':false,'Time':[],'authoritydescribe':'梯控模块'},
					]
				}
				this.QueryCs_CompanyAuthority();
			},
			//当前时间
			ThisTime(Day){
				var Time = new Date();
				Time.setDate(Time.getDate()+Day);
				var year = Time.getFullYear();
				var month = Time.getMonth()+1;
				var day = Time.getDate();
				if(month<10) month = "0"+month;
				if(day<10) day = "0"+day;
				var hours = Time.getHours();
				var mins = Time.getMinutes();
				var secs = Time.getSeconds();
				var msecs = Time.getMilliseconds();
				if(hours<10) hours = "0"+hours;
				if(mins<10) mins = "0"+mins;
				if(secs<10) secs = "0"+secs;
				if(msecs<10) secs = "0"+msecs;
				if(Day>0)
				{
					return year+"-"+month+"-"+day+" 23:59:59";
				}
				else
				{
					return year+"-"+month+"-"+day+" 00:00:00";
				}
			},
			//时间转为小时/天数
			TimeTransformDay(Time){
				if(Time!=null)
				{
					if(Time.length>0){
						var Time1 = new Date(Time[0]);
						var Time2 = new Date(Time[1]);
						var getTime = Time2.getTime() - Time1.getTime();
						var Value = parseInt(getTime / (1000 * 60 * 60 * 24));
						if(Value==0)
						{
							Value = Math.ceil(getTime / (1000 * 60 * 60))+"小时";
						}
						else
						{
							Value = Value+"天";
						}
						return Value;
					}
					else
					{
						return 0+"天";
					}
				}
				else
				{
					return 0+"天";
				}
			},
			// 基础功能开关
			BasicSwitch(e){
				if(!e)
				{
					var companyauthority = this.Form.companyauthority;
					for(var i=0;i<companyauthority.length;i++)
					{
						if(companyauthority[i].authoritykey!="Basic")
						{
							companyauthority[i].authorityvalue = false;
						}
					}
				}
			},
			// 功能模块开关
			ModuleSwitch(e){
				if(e){
					var companyauthority = this.Form.companyauthority;
					for(var i=0;i<companyauthority.length;i++)
					{
						if(companyauthority[i].authoritykey=="Basic")
						{
							companyauthority[i].authorityvalue = true;
						}
					}
				}
			},
			//公司上传LOGO限制
			beforeAvatarUpload(file){
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG && !isPNG) {
				  this.Message('上传Logo只能是 JPG 或 PNG 格式!','error');
				}
				if (!isLt2M) {
				  this.Message('上传Logo大小不能超过 2MB!','error');
				}
				return (isJPG || isPNG) && isLt2M;
			},
			//LOGO上传失败回调
			onError(res, file){
				this.Message('上传Logo失败','error');
			},
			//LOGO上传成功回调
			onSuccess(res, file){
				if (this.ReturnFiltration(res.code)) {
					this.Form.logo = res.result.Name;
					this.CompanyLogo = this.$CsImageUrl+res.result.Name;
				}
			},
			//新增修改公司信息
			addUpdateCsCompany(){
				this.$refs.RuleForm.validate(async valid => {
					if (!valid) return;
					this.DialogButtonLoad = true;//显示按钮加载动画
					var {data: res} = await this.$http.post('addUpdateCsCompany', this.Form);
					if (this.ReturnFiltration(res.code)) {
						if(res.result.Status=='1')
						{
							if(this.Form.companyid==0)
							{
								this.Message('添加成功',"success");
							}
							else{
								this.Message('修改成功',"success");
								//刷新权限
								this.QueryCs_CompanyAuthority();
								
							}
							this.QueryCs_Company();
							this.DialogVisible = false;
						}
						else if(res.result.Status=='2')
						{
							this.Message('公司名称已存在',"warning");
						}
						else
						{
							this.Message('操作失败',"error");
						}
					}
					this.DialogButtonLoad = false;//隐藏按钮加载动画
				})
			},
			//删除公司
			async deleteCsCompany(){
				if(this.TableCheckboxArray.length>0)
				{
					this.$prompt('请输入"确认删除"', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						inputPattern: /^确认删除$/,
						inputErrorMessage: '输入不正确'
					}).then(async ({ value }) => {
						var {data: res} = await this.$http.post('deleteCsCompany', this.TableCheckboxArray);
						if (this.ReturnFiltration(res.code)) {
							if(res.result.Status=='1')
							{
								this.Message('删除成功',"success");
								this.QueryCs_Company();
							}
							else
							{
								this.Message('删除失败',"error");
							}
						}
					}).catch(() => {
						//取消输入      
					});
				}
				else
				{
					this.Message('请选择要删除的公司',"warning");
				}
			},
			//复制激活码
			CopyKey(){
				var text =document.getElementById("KeyValue").innerText;
				var input = document.createElement('input');
				input.setAttribute('id', 'copyInput');
				input.setAttribute('value', text);
				document.getElementsByTagName('body')[0].appendChild(input);
				document.getElementById('copyInput').select();
				if (document.execCommand('copy')) {
					this.Message('复制成功',"success");
				}
				document.getElementById('copyInput').remove();
			},
			//重新激活
			ReActivation(){
				this.$confirm('是否重置激活状态！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var {data: res} = await this.$http.post('reActivation',{CompanyId:this.TableIndexCompany.CompanyId});
					if (this.ReturnFiltration(res.code)) {
						if(res.result.Status==1)
						{
							this.Message('重置成功',"success");
							this.QueryCs_Company();
						}
						else
						{
							this.Message('删除失败',"error");
						}
					}
				});
			},
			// 返回过滤
			ReturnFiltration(code) {
				if (code == "100001") {
					//成功
					return true;
				} else if (code == "100002") {
					//Session过期
					this.Message('身份已过期，请重新登录', "warning");
					return false;
				} else if (code == "100003") {
					//校验失败
					this.Message('校验失败，请重新登录', "error");
					return false;
				} else if (code == "100004") {
					//格式不正确
					this.Message('请求参数不正确', "error");
					return false;
				} else if (code == "100005") {
					//操作失败
					this.Message('操作失败', "error");
					return false;
				}
			},
			//提醒消息
			Message(message, type) {
				this.$message({showClose: true,message: message,type: type,offset: "14"});
			}
		}
	}
</script>

<style scoped>
	.Company {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		overflow: auto;
	}

	/* 左侧内容 */
	.Left {
		width: 70%;
		display: flex;
		flex-direction: column;
	}

	/* 右侧内容 */
	.Right {
		width: 28.8%;
		display: flex;
		flex-direction: column;
		background-color: #FFFFFF;
		border-radius: 10px;
	}

	/* 功能栏 */
	.ControlStrip {
		height: 60px;
		display: flex;
		justify-content: space-between;
	}

	/* 搜索栏 */
	.SearchBox {
		width: 38%;
		height: 100%;
		border-radius: 10px;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
	}

	/* 搜索图标 */
	.SearchBox>i {
		margin-left: 20px;
		margin-right: 5px;
		font-size: 25px;
		color: #909399;
	}

	/* 搜索框属性 */
	.SearchInput {
		width: 63%;
	}

	/* 去掉搜索框边框 */
	.SearchBox>>>.el-input__inner {
		border: 0;
	}

	/* 下拉框 */
	.split {
		border-right: 1px solid #C0C4CC;
		height: 25px;
	}

	/* 下拉框属性 */
	.ELSelect {
		width: 130px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	/* 日期框 */
	.DateBox {
		width: 37%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 10px;
		display: flex;
		justify-content: space-around;
	}

	/* 日期框名称 */
	.DateName {
		margin-left: 20px;
		font-size: 15px;
		color: #303133;
	}

	/* 去掉日期框边框 */
	.DateBox>>>.el-input__inner {
		border: 0;
	}

	/* 日期框图标 */
	.DateTime>>>i {
		font-size: 20px;
	}

	/* 功能按钮 */
	.Button {
		width: 21%;
		height: 100%;
		border-radius: 10px;
		background-color: #FFFFFF;
	}

	/* 功能按钮图标 */
	.ButtonIcon {
		width: 50%;
		height: 80%;
	}

	.ButtonIcon i {
		font-size: 30px;
		cursor: pointer;
	}

	/* 表格 */
	.Table {
		width: 100%;
		margin-top: 18px;
		flex: 1;
		border-radius: 10px;
		background-color: #FFFFFF;
	}

	/* 表格内容 */
	.Table-Content {
		width: 96%;
		height: 94%;
		display: flex;
		flex-direction: column;
	}

	/* 表格标题 */
	.Taoble-Title {
		font-size: 15px;
		color: #6F6F6F;
		font-weight: 700;
	}

	/* 表格备注 */
	.Taoble-Remark {
		margin-top: 25px;
		font-size: 14px;
		color: #AEAEAE;
	}

	/* 表格属性 */
	.Table-Attribute {
		margin-top: 10px;
		width: 100%;
	}

	/* 分页 */
	.paging {
		display: flex;
		align-items: center;
		margin-top: 20px;
		color: #606266;
	}

	/* 右侧头部菜单 */
	.Right-Top {
		height: 55px;
		border-radius: 10px 10px 0px 0px;
		display: flex;
		background-color: #E2E6EC;
	}

	/* 菜单按钮 */
	.Right-Top-button1 {
		height: 100%;
		width: 33.33%;
		font-size: 15px;
		color: #58666E;
		cursor: pointer;
	}

	.Right-Top-button2 {
		height: 100%;
		width: 33.33%;
		font-size: 15px;
		color: #58666E;
		border-radius: 10px 10px 0px 0px;
		background-color: #FFFFFF;
		cursor: pointer;
	}

	.Right-Top-Button {
		margin: 0px 10px;
		font-size: 18px;
		color: #58666E;
	}

	.Right-Top-button1:hover {
		color: #409EFF;
	}

	.Right-Top-button1:hover .Right-Top-Button {
		color: #409EFF;
	}

	.Right-Top-button2:hover {
		color: #409EFF;
	}

	.Right-Top-button2:hover .Right-Top-Button {
		color: #409EFF;
	}

	/* 右侧内容 */
	.Right-Content {
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;
	}

	/* 内容 */
	.IndexContent {
		width: 91%;
		height: 95%;
	}

	/* 内容1 */
	.Index1 {
		width: 90%;
		height: 95%;
	}

	/* 公司logo图片 */
	.Logo {
		width: 170px;
		height: 170px;
		border-radius: 10px;
		border: 1px solid #E2E6EC;
	}

	.Logo img {
		display: inline-block; 
		width: 90%; 
		max-width: 100%; 
		height: auto;
	}

	/* 公司名称 */
	.CompanyName {
		margin: 30px 0px;
		font-size: 23px;
		color: #808080;
	}

	/* 公司详情 */
	.CompanyDetails {
		color: #7B7979;
		font-size: 15px;
	}

	/* 公司详情项 */
	.CompanyDetails-Item {
		width: 100%;
		display: flex;
		margin: 10px 0px;
	}
	
	/* 权限详情 */
	.companyauthority-Item
	{
		width: 100%;
		display: flex;
	}
	/* 名称 */
	.ItemTitle {
		width: 20%;
	}

	/* 值 */
	.Item-Value {
		width: 80%;
	}

	/* 值描述 */
	.Item-Value-Details {
		margin: 0px 20px;
		color: #F56C6C;
		font-size: 14px;
	}

	/* 授权功能 */
	.Index2 {
		width: 100%;
		height: 100%;
	}

	/* 授权功能项 */
	.companyAuthority {
		margin-bottom: 20px;
		color: #7B7B7B;
	}

	/* 授权有效期 */
	.companyAuthority-Time {
		font-size: 15px;
		margin-bottom: 10px;
	}

	/* 权限更新历史 */
	.Index3 {
		width: 100%;
		height: 100%;
	}

	.UpdateHistory {
		width: 100%;
		height: 100%;
		margin-left: -40px;
	}

	.UpdateHistory p {
		color: #6F6F6F;
	}

	.UpdateHistory-Index {
		margin: 7px;
		color: #606266;
	}
	
	/* 表单 */
	.Dialog-Form{
		width: 100%;
		display: flex;
	}
	
	/* 表单左侧 */
	.Dialog-Form-Left{
		width: 70%;
	}
	
	/* 表单右侧 */
	.Dialog-Form-Right{
		width: 30%;
	}
	
	.Form-CompanyAuthority{
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.Form-CompanyAuthority-Switch{
		display: flex;
		align-items: center;
	}
	
	.Form-CompanyAuthority-Time{
		margin-left: 30px;
	}
	
	.Company-Logo{
		width: 200px;
		height: 200px;
		color: #8c939d;
		border: 1px dashed #909399;
		border-radius: 10px;
		margin: 0px auto;
	}
	
	.Company-Logo:hover{
		border: 1px dashed #409EFF;
		color: #409EFF;
	}
	
	.Company-Logo-button{
		margin-top: 5px;
		text-align: center;
	}
	
	.Company-Logo-button>button{
		width: 200px;
	}
	
	.Company-Logo-Title{
		margin-top: 15px;
		text-align: center;
	}
	
	.Form-notice{
		width: 70%;
		margin: 0px auto;
		margin-top: 50px;
		color: #606266;
	}
	
	.Form-notice>div{
		margin: 7px 0px;
		color: #909399;
	}
	
	.avatar-uploader-icon {
	  font-size: 28px;
	  width: 200px;
	  height: 200px;
	  line-height: 200px;
	  text-align: center;
	}
	
	.avatar{
		width: 100%;
	}
	
</style>
